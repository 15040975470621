import { Helmet } from "react-helmet-async";
import { Box, CircularProgress, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { baseURL } from "../../api/url";
import { useLineContext } from "../../hooks/useLineContext";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ImageIcon from "@mui/icons-material/Image";
import bgStar from "../../assets/images/bg/bg-star.png";
import RedButton from "../../components/ui/RedButton";
import dayjs from "dayjs";
import * as Yup from "yup";
import DialogSuccess from "../../components/ui/DialogSuccess";
import DialogError from "../../components/ui/DialogError";
import DialogLoading from "../../components/ui/DialogLoading";

const productSizeOptions = [
    {
        label: "SMALL",
        value: "SMALL",
        desc: "สินค้าขนาดเล็ก ส่งรวมกันได้",
    },
    {
        label: "MEDIUM",
        value: "MEDIUM",
        desc: "สินค้าขนาดกลาง ส่งรวมได้ สูงสุด 4 ชิ้น ส่งรวมกับสินค้าขนาดเล็กได้",
    },
    {
        label: "QUITE LARGE",
        value: "QUITE LARGE",
        desc: "สินค้าขนาดค่อนข้างใหญ่ ส่งแยกสูงสุด 2 ชิ้น",
    },
    {
        label: "LARGE",
        value: "LARGE",
        desc: "สินค้าขนาดใหญ่ ส่ง 1 ชิ้น รวมกับสินค้าขนาดเล็กได้",
    },
    {
        label: "SPECIAL",
        value: "SPECIAL",
        desc: "สินค้าพิเศษ ส่งแยกสูงสุด 1 ชิ้น",
    },
];

const AdminRewardDetailPage = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { liffToken } = useLineContext();
    const [isLoading, setIsLoading] = useState(true);
    const [successDialog, setSuccessDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const formik = useFormik({
        initialValues: {
            image: "",
            sku: "",
            name: "",
            detail: "",
            point: "",
            value: "",
            quantity: "",
            unit: "",
            stock: "",
            product_size: "",
            createTimestamp: "",
            updateTimestamp: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("โปรดระบุชื่อของรางวัล"),
            detail: Yup.string().required("โปรดระบุรายละเอียดของรางวัล"),
            point: Yup.string().required("โปรดระบุคะแนน").matches(/^\d+$/, "โปรดระบุคะแนนให้ถูกต้อง"),
            value: Yup.string().required("โปรดระบุมูลค่า").matches(/^\d+$/, "โปรดระบุมูลค่าให้ถูกต้อง"),
            quantity: Yup.string()
                .required("โปรดระบุจำนวนของรางวัลที่แลกต่อความ")
                .matches(/^\d+$/, "โปรดระบุจำนวนให้ถูกต้อง"),
            unit: Yup.string().required("โปรดระบุหน่วยของรางวัล"),
            stock: Yup.string().required("โปรดระบุจำนวนคงเหลือ").matches(/^\d+$/, "โปรดระบุจำนวนคงเหลือให้ถูกต้อง"),
            product_size: Yup.string().required("โปรดระบุตขนาดของรางวัล"),
        }),
        onSubmit: (datas) => {
            handleSubmitUpdateRewardInfo(datas);
        },
    });

    const fetchRewardDetail = useCallback(
        async (id) => {
            if (liffToken) {
                try {
                    const response = await axios.get(`${baseURL}/listreward`, {
                        headers: { Authorization: `Bearer ${liffToken}` },
                        params: {
                            reward_id: id,
                        },
                    });

                    if (response.data.Code === "20000") {
                        const data = response.data.Data[0];
                        formik.setValues({
                            image: data.image,
                            sku: data.sku,
                            name: data.name,
                            detail: data.detail,
                            point: data.point,
                            value: data.value,
                            quantity: data.quantity,
                            unit: data.unit,
                            stock: data.stock,
                            product_size: data.product_size,
                            createTimestamp: dayjs(data.create_timestamp).format("DD/MM/YYYY HH:mm:ss"),
                            updateTimestamp: dayjs(data.update_timestamp).format("DD/MM/YYYY HH:mm:ss"),
                        });
                    }

                    setIsLoading(false);
                } catch (e) {
                    console.error(e);
                    setIsLoading(false);
                }
            }
        },
        //eslint-disable-next-line
        [liffToken]
    );

    const handleSubmitUpdateRewardInfo = useCallback(
        async (datas) => {
            if (liffToken) {
                try {
                    setLoadingDialog(true);
                    setErrorMessage("");

                    const response = await axios.post(`${baseURL}/update_info_reward`, datas, {
                        headers: { Authorization: `Bearer ${liffToken}` },
                        params: { reward_id: params.id },
                    });

                    if (response.data.Code === "20100" || response.data.Code === "20000") {
                        setLoadingDialog(false);
                        setSuccessDialog(true);
                    } else {
                        setLoadingDialog(false);
                        setErrorDialog(true);
                        setErrorMessage(response.data.DeveloperMessage);
                    }
                } catch (e) {
                    console.error(e);
                    setLoadingDialog(false);
                    setErrorDialog(true);
                    setErrorMessage(e.message);
                }
            }
        },
        [liffToken, params.id]
    );

    const handleCloseSuccess = () => {
        setSuccessDialog(false);
        navigate("/admin#4");
    };

    useEffect(() => {
        fetchRewardDetail(params.id);
    }, [fetchRewardDetail, params.id]);

    return (
        <>
            <Helmet>
                <title>จัดการของรางวัล | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                mx="auto"
                sx={{
                    height: "100vh",
                    overflow: "hidden",
                    background: `url(${bgStar}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box
                    component="section"
                    maxWidth="lg"
                    mx="auto"
                    sx={{ padding: "6rem 1.25rem 40px", height: "100%", overflow: "auto" }}
                >
                    <Box sx={{ position: "relative" }}>
                        <Typography
                            sx={{
                                fontSize: "1.5rem",
                                fontWeight: "500",
                                color: "#002B38",
                                textAlign: "center",
                                marginBottom: "1.25rem",
                            }}
                        >
                            จัดการของรางวัล
                        </Typography>

                        <IconButton
                            onClick={() => navigate("/admin#4")}
                            sx={{
                                fontSize: "1.25rem",
                                color: "#004B61",
                                position: "absolute",
                                top: "0",
                                left: "10px",
                                padding: "0",
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }}
                        >
                            <ArrowBackIosIcon
                                sx={{
                                    fontSize: "1rem",
                                    color: "#004B61",
                                }}
                            />
                            ย้อนกลับ
                        </IconButton>
                    </Box>

                    <Box my={3} sx={{ backgroundColor: "white", borderRadius: "8px" }}>
                        {isLoading && (
                            <Box sx={{ padding: "40px" }}>
                                <CircularProgress sx={{ display: "block", margin: "50px auto" }} />
                            </Box>
                        )}

                        {!isLoading && (
                            <Box
                                component="form"
                                onSubmit={formik.handleSubmit}
                                sx={{
                                    display: "grid",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "20px",
                                    gridTemplateColumns: "0.7fr 1fr",
                                }}
                            >
                                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {formik.values.image ? (
                                        <Box
                                            component="img"
                                            src={formik.values.image}
                                            alt={formik.values.name}
                                            sx={{ width: "85%" }}
                                        />
                                    ) : (
                                        <ImageIcon sx={{ fontSize: "80px", color: "#333", textAlign: "center" }} />
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        backgroundColor: "#f5f5f5",
                                        padding: "40px",
                                        borderTopRightRadius: "8px",
                                        borderBottomRightRadius: "8px",
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr",
                                        gap: "15px",
                                    }}
                                >
                                    <Box>
                                        <Label>SKU:</Label>
                                        <Input disabled value={formik.values.sku} />
                                    </Box>
                                    <Box>
                                        <Label htmlFor="name">ชื่อ:</Label>
                                        <Input
                                            id="name"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.name && formik.touched.name && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>{formik.errors.name}</Box>
                                        )}
                                    </Box>
                                    <Box sx={{ gridColumn: "1 / span 2" }}>
                                        <Label htmlFor="detail">รายละเอียด:</Label>
                                        <Input
                                            id="detail"
                                            name="detail"
                                            multiline
                                            minRows={3}
                                            value={formik.values.detail}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.detail && formik.touched.detail && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>{formik.errors.detail}</Box>
                                        )}
                                    </Box>
                                    <Box>
                                        <Label htmlFor="point">คะแนนที่ใช้ในการแลก:</Label>
                                        <Input
                                            id="point"
                                            name="point"
                                            value={formik.values.point}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.point && formik.touched.point && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>{formik.errors.point}</Box>
                                        )}
                                    </Box>
                                    <Box>
                                        <Label htmlFor="value">มูลค่า (บาท):</Label>
                                        <Input
                                            id="value"
                                            name="value"
                                            value={formik.values.value}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.value && formik.touched.value && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>{formik.errors.value}</Box>
                                        )}
                                    </Box>
                                    <Box>
                                        <Label htmlFor="quantity">จำนวนรางวัลที่ได้รับ:</Label>
                                        <Input
                                            id="quantity"
                                            name="quantity"
                                            value={formik.values.quantity}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.quantity && formik.touched.quantity && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>{formik.errors.quantity}</Box>
                                        )}
                                    </Box>
                                    <Box>
                                        <Label htmlFor="unit">หน่วย:</Label>
                                        <Input
                                            id="unit"
                                            name="unit"
                                            value={formik.values.unit}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.unit && formik.touched.unit && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>{formik.errors.unit}</Box>
                                        )}
                                    </Box>
                                    <Box sx={{ gridColumn: "1 / span 2" }}>
                                        <Label htmlFor="stock">คงเหลือ:</Label>
                                        <Input
                                            id="stock"
                                            name="stock"
                                            value={formik.values.stock}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.stock && formik.touched.stock && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>{formik.errors.stock}</Box>
                                        )}
                                    </Box>
                                    <Box sx={{ gridColumn: "1 / span 2" }}>
                                        <Label htmlFor="product_size">ขนาด:</Label>
                                        <Select
                                            id="product_size"
                                            name="product_size"
                                            value={formik.values.product_size}
                                            onChange={formik.handleChange}
                                            sx={{
                                                display: "block",
                                                width: "100%",
                                                backgroundColor: "white",
                                                borderRadius: "8px",
                                                height: "42px",
                                                color: "#004B61",
                                                "& .MuiSelect-select": {
                                                    padding: "10px",
                                                },
                                                "& .MuiOutlinedInput-notchedOutline": {
                                                    borderColor: "#004B61",
                                                },
                                            }}
                                        >
                                            {productSizeOptions.map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <Box sx={{ fontSize: "14px", color: "#505050" }}>
                                            *
                                            {
                                                productSizeOptions.find(
                                                    (item) => item.value === formik.values.product_size
                                                ).desc
                                            }
                                        </Box>
                                        {formik.errors.product_size && formik.touched.product_size && (
                                            <Box sx={{ color: "red", fontSize: "14px" }}>
                                                {formik.errors.product_size}
                                            </Box>
                                        )}
                                    </Box>
                                    <Box sx={{ gridColumn: "1 / span 2" }}>
                                        <Label>Create timestamp:</Label>
                                        <Input disabled value={formik.values.createTimestamp} />
                                    </Box>
                                    <Box sx={{ gridColumn: "1 / span 2" }}>
                                        <Label>Update timestamp:</Label>
                                        <Input disabled value={formik.values.updateTimestamp} />
                                    </Box>

                                    <Box sx={{ gridColumn: "1 / span 2" }}>
                                        <RedButton type="submit" sx={{ width: "150px", marginTop: "20px" }}>
                                            บันทึก
                                        </RedButton>
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>

                    <DialogSuccess
                        isOpen={successDialog}
                        onClose={handleCloseSuccess}
                        text="แก้ไขข้อมูลของรางวัลสำเร็จ"
                        btnText="ปิด"
                    />

                    <DialogError
                        isOpen={errorDialog}
                        onClose={() => setErrorDialog(false)}
                        text={errorMessage}
                        btnText="ปิด"
                    />

                    <DialogLoading open={loadingDialog} message="กำลังแก้ไขข้อมูลของรางวัล" />
                </Box>
            </Box>
        </>
    );
};

const Label = (props) => {
    return (
        <Box
            component="label"
            sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#333",
                marginBottom: "4px",
            }}
            {...props}
        >
            {props.children}
        </Box>
    );
};

const Input = (props) => {
    return (
        <TextField
            fullWidth
            sx={{
                fontSize: "0.875rem",
                borderRadius: "8px",
                "& .MuiInputBase-root": {
                    borderRadius: "8px",
                    backgroundColor: "white",
                },
                "& .MuiInputBase-multiline": {
                    padding: "5px 0",
                },
                "& .MuiInputBase-input": {
                    padding: "5px 10px",
                    height: "32px",
                    color: "#004B61",
                },
                "& .MuiInputBase-input::placeholder": {
                    fontSize: "0.875rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "8px",
                    border: "1px solid #004B61",
                },
            }}
            {...props}
        />
    );
};

export default AdminRewardDetailPage;
