import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import { useAppContext } from "../../hooks/useAppContext";
import PrivilegeList from "./components/PrivilegeList";
import bgStar from "../../assets/images/bg/bg-star.png";
import privilegeBg from "../../assets/images/pages/privilege/privilege-bg.png";
import starRedIcon from "../../assets/icon/star-red.svg";

const PrivilegePage = () => {
    const { userInfo } = useAppContext();

    return (
        <>
            <Helmet>
                <title>Privilege | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                maxWidth="md"
                mx="auto"
                sx={{
                    minHeight: "100vh",
                    background: `url(${bgStar}) no-repeat center center / cover fixed`,
                }}
            >
                <Box component="section" sx={{ padding: "80px 0 120px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            position: "relative",
                            overflow: 'hidden'
                        }}
                    >
                        <Box component="img" src={privilegeBg} alt="Reward Background" sx={{ width: "100%", transform: 'translateX(-10px)' }} />
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "absolute",
                                top: "0",
                                left: "0",
                                padding: ".5rem 1.5rem .5rem 2rem",
                            }}
                        >
                            <Box sx={{ fontSize: "1.5rem", fontWeight: "500", color: "#004B61" }}>Privilege</Box>
                            <Box>
                                <Box sx={{ fontSize: "1rem", fontWeight: "500", color: "#FF0000", textAlign: "right" }}>
                                    คะแนนสะสม
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                        fontSize: "1.75rem",
                                        lineHeight: "1.75rem",
                                        fontWeight: "500",
                                        color: "#FF0000",
                                    }}
                                >
                                    <Box component="img" src={starRedIcon} alt="Star" sx={{ height: "22px" }} />
                                    {userInfo?.accumulated_points || "0"}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <PrivilegeList />
                </Box>
            </Box>
        </>
    );
};

export default PrivilegePage;
