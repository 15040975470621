import axios from "axios";
import { Box, Button, CircularProgress, Dialog, Paper, Typography } from "@mui/material";
import { useLineContext } from "../../../hooks/useLineContext";
import { useCallback, useEffect, useState } from "react";
import { baseURL } from "../../../api/url";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import ImageIcon from "@mui/icons-material/Image";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import WhiteButton from "../../../components/ui/WhiteButton";
import RedButton from "../../../components/ui/RedButton";
import DialogSuccess from "../../../components/ui/DialogSuccess";
import DialogError from "../../../components/ui/DialogError";
import DialogLoading from "../../../components/ui/DialogLoading";

const customStyles = {
    headRow: {
        style: {
            fontSize: ".75rem",
            color: "white",
            background: "linear-gradient(180deg, #006B7D, #005D76, #00475B)",
        },
    },
    headCells: {
        style: {
            justifyContent: "center",
            borderLeft: "1px solid white",
        },
    },
    rows: {
        style: {
            background: "#E7FBFF",
            cursor: "pointer",
            "&:not(:last-of-type)": {
                borderBottomColor: "white",
            },
        },
    },
    cells: {
        style: {
            padding: ".5rem",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const AdminTabFour = () => {
    const navigate = useNavigate();
    const { liffToken } = useLineContext();
    const [listReward, setListReward] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedReward, setSelectedReward] = useState({});
    const [confirmDialog, setConfirmDialog] = useState(false);
    const [successDialog, setSuccessDialog] = useState(false);
    const [errorDialog, setErrorDialog] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const columns = [
        {
            id: "image",
            name: "รูปภาพ",
            width: "150px",
            sortable: true,
            cell: (row) => {
                if (row.image[0]) {
                    return (
                        <Box
                            component="img"
                            src={row.image[0]}
                            alt={row.name}
                            sx={{ height: "100px", display: "block", margin: "0 auto" }}
                        />
                    );
                }

                return <ImageIcon sx={{ height: "100px", display: "block", margin: "0 auto" }} />;
            },
            style: {
                justifyContent: "center",
            },
        },
        {
            id: "name",
            name: "ชื่อของรางวัล",
            minWidth: "200px",
            sortable: true,
            selector: (row) => row.name || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "point",
            name: "คะแนน",
            minWidth: "60px",
            sortable: true,
            selector: (row) => row.point || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "stock",
            name: "คงเหลือ",
            minWidth: "120px",
            sortable: true,
            selector: (row) => row.stock || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "product_size",
            name: "ขนาดสินค้า",
            minWidth: "120px",
            sortable: true,
            selector: (row) => row.product_size || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "action",
            name: "",
            minWidth: "40px",
            sortable: true,
            cell: (row) => (
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "10px" }}>
                    <Button
                        onClick={() => navigate(`/admin/reward/${row._id}`)}
                        sx={{
                            minWidth: "30px",
                            padding: "2px 4px",
                            color: "#6b7280",
                            "&:hover": {
                                color: "#ef4444",
                            },
                        }}
                    >
                        <ModeEditIcon sx={{ width: "20px" }} />
                    </Button>
                    <Button
                        onClick={() => handleDeleteReward(row._id, row.name)}
                        sx={{
                            minWidth: "30px",
                            padding: "2px 4px",
                            color: "#6b7280",
                            "&:hover": {
                                color: "#ef4444",
                            },
                        }}
                    >
                        <DeleteIcon sx={{ width: "20px" }} />
                    </Button>
                </Box>
            ),
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
    ];

    const handleDeleteReward = (rewardId, rewardName) => {
        setConfirmDialog(true);
        setSelectedReward({
            id: rewardId,
            name: rewardName,
        });
    };

    const handleCloseDialog = () => {
        setConfirmDialog(false);
        setSelectedReward({});
    };

    const handleCloseSuccess = () => {
        setSuccessDialog(false);
        fetchListReward();
    };

    const handleDeleteRewardAPI = useCallback(async () => {
        if (liffToken) {
            try {
                setConfirmDialog(false);
                setLoadingDialog(true);

                const response = await axios.post(
                    `${baseURL}/update_info_reward`,
                    {
                        remove_status: true,
                    },
                    {
                        headers: { Authorization: `Bearer ${liffToken}` },
                        params: { reward_id: selectedReward.id },
                    }
                );

                if (response.data.Code === "20000") {
                    setLoadingDialog(false);
                    setSuccessDialog(true);
                } else {
                    setLoadingDialog(false);
                    setErrorDialog(true);
                    setErrorMessage(response.data.DeveloperMessage);
                }
            } catch (e) {
                console.error(e);
                setLoadingDialog(false);
                setErrorDialog(true);
                setErrorMessage(e.message);
            } finally {
                setSelectedReward({});
            }
        }
    }, [liffToken, selectedReward]);

    const fetchListReward = useCallback(async () => {
        if (liffToken) {
            try {
                setIsLoading(true);
                const response = await axios.get(`${baseURL}/listreward`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                });

                if (response.data.Code === "20000") {
                    setListReward(response.data.Data);
                } else {
                    setListReward([]);
                }

                setIsLoading(false);
            } catch (e) {
                console.error(e);
                setIsLoading(false);
            }
        }
    }, [liffToken]);

    useEffect(() => {
        fetchListReward();
    }, [fetchListReward]);

    return (
        <Box>
            <Box
                p={1}
                sx={{
                    backgroundColor: "white",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                    border: "2px solid white",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
            >
                <DataTable
                    pagination
                    columns={columns}
                    data={listReward}
                    customStyles={customStyles}
                    progressPending={isLoading}
                    defaultSortAsc
                    defaultSortFieldId="point"
                    progressComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "#E7FBFF",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    noDataComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "#E7FBFF",
                            }}
                        >
                            <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                ไม่พบรายชื่อของรางวัล
                            </Typography>
                        </Box>
                    }
                />
            </Box>

            <Dialog open={confirmDialog} onClose={handleCloseDialog}>
                <Paper sx={{ minWidth: "450px", backgroundColor: "white", padding: "2rem" }}>
                    <Box sx={{ fontSize: "18px", textAlign: "center", letterSpacing: "0.5px" }}>
                        <Box component="span" sx={{ fontSize: "20px", fontWeight: "500" }}>
                            คุณต้องการลบรางวัล
                        </Box>
                        <br />
                        <Box component="span" sx={{ color: "red" }}>
                            {selectedReward.name}
                        </Box>
                        <br />
                        ใช่หรือไม่
                    </Box>
                    <Box
                        sx={{
                            paddingTop: "2rem",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "10px",
                        }}
                    >
                        <WhiteButton sx={{ borderRadius: "8px", width: "100px" }} onClick={handleCloseDialog}>
                            ยกเลิก
                        </WhiteButton>
                        <RedButton sx={{ borderRadius: "8px", width: "100px" }} onClick={handleDeleteRewardAPI}>
                            ตกลง
                        </RedButton>
                    </Box>
                </Paper>
            </Dialog>

            <DialogSuccess
                isOpen={successDialog}
                onClose={handleCloseSuccess}
                text="ลบข้อมูลของรางวัลสำเร็จ"
                btnText="ปิด"
            />

            <DialogError isOpen={errorDialog} onClose={() => setErrorDialog(false)} text={errorMessage} btnText="ปิด" />

            <DialogLoading open={loadingDialog} message="กำลังลบข้อมูลของรางวัล" />
        </Box>
    );
};
export default AdminTabFour;
