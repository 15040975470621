import { Box, Typography } from "@mui/material";

const Footer = (props) => {
    const { renderText, icon, iconStyle, textColor } = props;

    return (
        <Box
            component="footer"
            maxWidth="md"
            mx="auto"
            sx={{
                width: "100%",
                height: "100px",
                display: "flex",
                alignItems: "center",
                position: "relative",
            }}
        >
            {renderText && (
                <Box sx={{ padding: textColor ? "0" : "0 1.25rem" }}>
                    <Typography
                        component="h3"
                        sx={{ fontSize: "1.5rem", fontWeight: "bold", lineHeight: "2rem", color: textColor || "white" }}
                    >
                        สตาร์การ์ด คลับ 2025
                    </Typography>
                    <Typography
                        component="p"
                        sx={{ fontSize: "1.2rem", fontWeight: 'medium', lineHeight: "1.75rem", color: textColor || "white" }}
                    >
                        จากโรงกลั่น..สู่ธุรกิจคุณ
                    </Typography>
                    <Typography component="p" sx={{ fontSize: "0.85rem", color: textColor || "white" }}>
                        เปลี่ยนทุกลิตรเป็นคะแนน แลกของรางวัลที่ถูกใจ
                    </Typography>
                </Box>
            )}
            {icon && <Box component="img" src={icon} alt="Icon" sx={{ ...iconStyle }} />}
        </Box>
    );
};

export default Footer;
