import { Box } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "../hooks/useAppContext";
import { useLineContext } from "../hooks/useLineContext";
import { useEffect, useState } from "react";
import mainLogo from "../assets/images/logo/main-logo.png";
import mainLogoDark from "../assets/images/logo/main-logo-dark.png";
import bgHeader from "../assets/images/bg/bg-header.png";
import Cart from "../components/cart/Cart";
import _ from "lodash";
import DialogInfo from "../components/ui/DialogInfo";

const Header = () => {
    const { lineProfile } = useLineContext();
    const { redeemLists, companyInfo, headerWhiteTheme } = useAppContext();
    const location = useLocation();
    const navigate = useNavigate();
    const [isOwner, setIsOwner] = useState(false);
    const [dialogInfo, setDialogInfo] = useState(false);
    const [dialogState, setDialogState] = useState(false);

    const handleShoppingCart = () => {
        if (companyInfo?.state !== "ACCOUNT TERMINATE") {
            setDialogState(false);

            if (isOwner) {
                navigate("/shopping");
            } else {
                setDialogInfo(true);
            }
        } else {
            setDialogState(true);
        }
    };

    useEffect(() => {
        const filtered = _.find(companyInfo.user, { userId: lineProfile.userId });
        if (filtered) {
            setIsOwner(filtered.owner);
        }
    }, [companyInfo, lineProfile]);

    return (
        <Box
            component="header"
            maxWidth={`${location.pathname.includes("/admin") ? false : "md"}`}
            sx={{
                width: "100%",
                margin: "0 auto",
                position: "absolute",
                top: "0",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 2,
            }}
        >
            <Box
                maxWidth={`${location.pathname.includes("/admin") ? false : "md"}`}
                sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0",
                    background: `url(${bgHeader}) no-repeat center center / cover, #00566c`,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        width: "100%",
                        height: "60px",
                    }}
                >
                    <Link to="/home">
                        <img
                            src={headerWhiteTheme ? mainLogo : mainLogoDark}
                            alt="Starcard Club"
                            style={{ height: "55px", display: "block" }}
                        />
                    </Link>
                </Box>
                <Box sx={{ height: "60px" }}>
                    {isOwner && location.pathname.includes("/reward") && (
                        <Box onClick={handleShoppingCart}>
                            <Cart quantity={redeemLists.length || 0} />
                        </Box>
                    )}
                </Box>
            </Box>

            <DialogInfo
                isOpen={dialogInfo}
                onClose={() => setDialogInfo(false)}
                text={`ไม่สามารถแลกของรางวัลได้\nเนื่องจากไม่มีสิทธิ์ในการแลก`}
                btnText="ปิด"
            />

            <DialogInfo
                isOpen={dialogState}
                onClose={() => setDialogState(false)}
                text={`กรุณาติดต่อ\nฝ่ายขายแผนกบัตร\nคาลเท็กซ์สตาร์การ์ด`}
                btnText="ปิด"
            />
        </Box>
    );
};

export default Header;
