import { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, InputAdornment, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../api/url";
import { useLineContext } from "../../../hooks/useLineContext";
import SearchIcon from "@mui/icons-material/Search";
import RedButton from "../../../components/ui/RedButton";
import DataTable from "react-data-table-component";
import dayjs from "dayjs";
import axios from "axios";
import { utils, writeFileXLSX } from "xlsx";

const customStyles = {
    headRow: {
        style: {
            fontSize: ".75rem",
            color: "white",
            background: "linear-gradient(180deg, #006B7D, #005D76, #00475B)",
        },
    },
    headCells: {
        style: {
            justifyContent: "center",
            borderLeft: "1px solid white",
        },
    },
    rows: {
        style: {
            background: "#E7FBFF",
            cursor: "pointer",
            "&:not(:last-of-type)": {
                borderBottomColor: "white",
            },
        },
    },
    cells: {
        style: {
            padding: ".5rem",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const AdminTabOne = () => {
    const navigate = useNavigate();
    const { liffToken } = useLineContext();
    const [search, setSearch] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 50,
        sortColumn: "code",
        isAsc: true,
    });
    const [totalRecords, setTotalRecords] = useState(0);

    const columns = [
        {
            id: "code",
            name: "รหัสบริษัท",
            width: "120px",
            sortable: true,
            selector: (row) => row.code || "-",
            style: {
                justifyContent: "center",
            },
        },
        {
            id: "company_name",
            name: "ชื่อบริษัท",
            minWidth: "200px",
            sortable: true,
            selector: (row) => row.company_name || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "people_name",
            name: "ชื่อผู้มีอำนาจ",
            minWidth: "200px",
            sortable: true,
            selector: (row) => row.people_name || "-",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "accumulated_points",
            name: "คะแนนสะสม",
            minWidth: "80px",
            sortable: true,
            selector: (row) => parseFloat(row.accumulated_points.toFixed(2)) || "0",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "spending_criteria",
            name: "เกณฑ์การให้คะแนน",
            minWidth: "80px",
            sortable: true,
            selector: (row) => parseFloat(row.spending_criteria.toFixed(2)) || "0",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "spending_amount",
            name: "ยอดที่ใช้ไปล่าสุด",
            minWidth: "80px",
            sortable: true,
            selector: (row) => parseFloat(row.spending_amount.toFixed(2)) || "0",
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            id: "register_timestamp",
            name: "วันที่สมัคร",
            minWidth: "80px",
            sortable: true,
            selector: (row) => (row.register_timestamp ? row.register_timestamp : "-"),
            format: (row) => (row.register_timestamp ? dayjs(row.register_timestamp).format("DD/MM/YYYY") : "-"),
            style: {
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
    ];

    const fetchCompanyList = useCallback(
        async (page, limit, search, sortColumn, isAsc) => {
            if (liffToken) {
                try {
                    const response = await axios.get(`${baseURL}/company_list`, {
                        headers: {
                            Authorization: `Bearer ${liffToken}`,
                        },
                        params: {
                            page,
                            limit,
                            search,
                            sort_col: sortColumn,
                            sort_value: isAsc,
                        },
                    });

                    if (response.data.Code === "20000") {
                        setCompanyList(response.data.Data.data);
                        setTotalRecords(response.data.Data.total);
                        setIsLoading(false);
                    }
                } catch (e) {
                    setIsLoading(false);
                    console.error(e);
                }
            }
        },
        [liffToken]
    );

    const handleRowClicked = (row) => {
        navigate(`/admin/${row.code}`);
    };

    const handleSort = (selectedColumn, sortDirection) => {
        setIsLoading(true);
        setPagination((prev) => ({
            ...prev,
            sortColumn: selectedColumn.id || "code",
            isAsc: sortDirection === "asc",
        }));
    };

    const handlePerRowsChange = (newPerPage) => {
        setIsLoading(true);
        setPagination((prev) => ({
            ...prev,
            page: 1,
            perPage: newPerPage,
        }));
    };

    const handlePageChange = (page) => {
        setIsLoading(true);
        setPagination((prev) => ({
            ...prev,
            page,
        }));
    };

    const handleDownloadExcel = (dataSource, sheetName, fileName, headerOrder = { header: [] }) => {
        const ws = utils.json_to_sheet(dataSource, headerOrder);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, sheetName);
        writeFileXLSX(wb, `${fileName}.xlsx`);
    };

    const handleExport = async () => {
        try {
            const response = await axios.get(`${baseURL}/company_list`, {
                headers: {
                    Authorization: `Bearer ${liffToken}`,
                },
                params: {
                    page: 1,
                    limit: "all",
                    search: "",
                    sort_col: "code",
                    sort_value: true,
                },
            });

            if (response.data.Code === "20000") {
                const formatData = response.data.Data.data.map((item) => ({
                    code: item.code,
                    company_name: item.company_name,
                    people_name: item.people_name,
                    spending_amount: item.spending_amount,
                    spending_criteria: item.spending_criteria,
                    accumulated_points: item.accumulated_points,
                    create_timestamp: item.create_timestamp,
                    update_timestamp: item.update_timestamp,
                    register_timestamp: item.register_timestamp,
                    remove_status: item.remove_status,
                    state: item.state,
                }));

                handleDownloadExcel(
                    formatData,
                    "Company list",
                    `STARCARD_CLUB_COMPANY_${dayjs().format("DD-MMM-YYYY")}`,
                    {
                        header: [
                            "code",
                            "company_name",
                            "people_name",
                            "spending_amount",
                            "spending_criteria",
                            "accumulated_points",
                            "create_timestamp",
                            "update_timestamp",
                            "register_timestamp",
                            "remove_status",
                            "state",
                        ],
                    }
                );
            }
        } catch (e) {
            console.error(e);
        }
    };

    const handleSearch = (e) => {
        setPagination(prev => ({
            ...prev,
            page: 1
        }))

        setSearch(e.target.value)
    }

    useEffect(() => {
        const timmerId = setTimeout(
            () =>
                fetchCompanyList(pagination.page, pagination.perPage, search, pagination.sortColumn, pagination.isAsc),
            500
        );
        return () => clearTimeout(timmerId);
    }, [pagination, search, fetchCompanyList]);

    return (
        <Box>
            <Box
                p={1}
                sx={{
                    backgroundColor: "white",
                    borderBottomLeftRadius: "15px",
                    borderBottomRightRadius: "15px",
                    border: "2px solid white",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
            >
                <Box display="flex" alignItems="center" gap={1} mb={2}>
                    <TextField
                        fullWidth
                        placeholder="ค้นหารหัสบริษัท, ชื่อบริษัท"
                        value={search}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon sx={{ color: "#004B61" }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            fontSize: "0.875rem",
                            borderRadius: "15px",
                            "& .MuiInputBase-root": {
                                borderRadius: "15px",
                                backgroundColor: "white",
                            },
                            "& .MuiInputBase-input": {
                                padding: "0",
                                height: "40px",
                                color: "#004B61",
                            },
                            "& .MuiInputBase-input::placeholder": {
                                fontSize: "0.875rem",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "15px",
                                border: "2px solid #004B61",
                            },
                        }}
                    />
                    <Box
                        sx={{
                            width: "190px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                        }}
                    >
                        <RedButton sx={{ fontSize: ".75rem" }} onClick={handleExport}>
                            Export Excel
                        </RedButton>
                    </Box>
                </Box>
                <DataTable
                    pagination
                    paginationServer
                    paginationPerPage={pagination.perPage}
                    paginationTotalRows={totalRecords}
                    paginationDefaultPage={pagination.page}
                    paginationRowsPerPageOptions={[50, 100, 500]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    columns={columns}
                    data={companyList}
                    customStyles={customStyles}
                    progressPending={isLoading}
                    onRowClicked={handleRowClicked}
                    onSort={handleSort}
                    defaultSortFieldId={pagination.sortColumn}
                    defaultSortAsc={pagination.isAsc}
                    progressComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "#E7FBFF",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    }
                    noDataComponent={
                        <Box
                            py={5}
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "#E7FBFF",
                            }}
                        >
                            <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                ไม่พบรายชื่อบริษัท
                            </Typography>
                        </Box>
                    }
                />
            </Box>
        </Box>
    );
};

export default AdminTabOne;
