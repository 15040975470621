import { Box, Grid, IconButton, Radio, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLineContext } from "../../../hooks/useLineContext";
import { useAppContext } from "../../../hooks/useAppContext";
import { baseURL } from "../../../api/url";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import axios from "axios";

const AddressCard = (props) => {
    const { liffToken } = useLineContext();
    const { setCompanyInfo } = useAppContext();
    const navigate = useNavigate();

    const onRadioChange = (e) => {
        props.setSelected(e.target.value);

        axios
            .post(
                `${baseURL}/company_change_address`,
                {
                    code_company: props.code,
                    address: {
                        _id: e.target.value,
                        default: true,
                    },
                },
                {
                    headers: { Authorization: `Bearer ${liffToken}` },
                }
            )
            .then((res) => {
                if (res.data.Code === "20000") {
                    setCompanyInfo(res.data.Data);
                    navigate(-1);
                }
            })
            .catch((e) => {
                throw new Error("Can't update new address", e);
            });
    };

    return (
        <Box
            sx={{
                padding: "15px",
                marginBottom: "20px",
                background: "linear-gradient(90deg, #E3EFFF, #FFFFFF)",
                border: "2px solid white",
                borderRadius: "20px",
                position: "relative",
            }}
        >
            <Grid container mb={2}>
                <Grid item xs={4}>
                    <Radio
                        name={props.addressId}
                        checked={props.selected === props.addressId}
                        value={props.addressId}
                        onChange={onRadioChange}
                        sx={{
                            padding: "0",
                            color: "#004B61",
                            "&.Mui-checked": {
                                color: "#004B61",
                            },
                        }}
                    />
                </Grid>
                <Grid item xs={8} display="flex" justifyContent="flex-end" gap={1.5}>
                    <IconButton
                        aria-label="edit"
                        sx={{ padding: "0", color: "#004B61" }}
                        onClick={() => navigate(`/address/edit/${props.addressId}`)}
                    >
                        <EditIcon sx={{ fontSize: "1.25rem" }} />
                    </IconButton>
                    <IconButton
                        aria-label="delete"
                        sx={{ padding: "0", color: "#004B61" }}
                        onClick={() => props.onDelete(props.code, props.addressId)}
                    >
                        <DeleteForeverIcon sx={{ fontSize: "1.25rem" }} />
                    </IconButton>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "500", color: "#333" }}>
                        ชื่อ - นามสกุล
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "300", color: "#333" }}>
                        {props.name || "-"}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "500", color: "#333" }}>เบอร์โทรศัพท์</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "300", color: "#333" }}>
                        {props.phonenumber || "-"}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "500", color: "#333" }}>ที่อยู่</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "300", color: "#333", wordBreak: "break-word" }}>
                        {`${props.detail || ""} ${props.alley || ""} ${props.street || ""}`}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "500", color: "#333" }}>ตำบล</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "300", color: "#333" }}>
                        {props.subdistrict || "-"}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "500", color: "#333" }}>อำเภอ</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "300", color: "#333" }}>
                        {props.district || "-"}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "500", color: "#333" }}>จังหวัด</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "300", color: "#333" }}>
                        {props.province || "-"}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "500", color: "#333" }}>รหัสไปรษณีย์</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Typography sx={{ fontSize: ".85rem", fontWeight: "300", color: "#333" }}>
                        {props.zipcode || "-"}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AddressCard;
