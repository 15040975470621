import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Box, Button } from "@mui/material";
import bgStar from "../../assets/images/bg/bg-star.png";
import AdminTabOne from "./components/AdminTabOne";
import AdminTabTwo from "./components/AdminTabTwo";
import AdminTabThree from "./components/AdminTabThree";
import AdminTabFour from "./components/AdminTabFour";
import { useLocation, useNavigate } from "react-router-dom";

const AdminPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [tabActive, setTabActive] = useState(1);

    const handleTabChange = (idx) => {
        setTabActive(idx);
        navigate("/admin", { replace: true });
    };

    useEffect(() => {
        if (location.hash) {
            const newHash = location.hash.replace(/#/g, "");

            setTabActive(+newHash);
        }
    }, [location.hash]);

    return (
        <>
            <Helmet>
                <title>รายชื่อบริษัททั้งหมด | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                mx="auto"
                sx={{
                    minHeight: "100vh",
                    background: `url(${bgStar}) no-repeat center center / cover`,
                }}
            >
                <Box component="section" maxWidth="lg" mx="auto" sx={{ padding: "80px 1.25rem" }}>
                    <Box
                        sx={{
                            width: "fit-content",
                            display: "flex",
                            alignItems: "center",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                        }}
                    >
                        <Button
                            onClick={() => handleTabChange(1)}
                            sx={{
                                color: tabActive === 1 ? "white" : "#002B38",
                                backgroundColor: tabActive === 1 ? "#002B38" : "white",
                                borderRadius: "0",
                                borderTopLeftRadius: ".5rem",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            รายชื่อบริษัททั้งหมด
                        </Button>
                        <Button
                            onClick={() => handleTabChange(2)}
                            sx={{
                                color: tabActive === 2 ? "white" : "#002B38",
                                backgroundColor: tabActive === 2 ? "#002B38" : "white",
                                borderRadius: "0",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            ประวัติการแลกรางวัล
                        </Button>
                        <Button
                            onClick={() => handleTabChange(3)}
                            sx={{
                                color: tabActive === 3 ? "white" : "#002B38",
                                backgroundColor: tabActive === 3 ? "#002B38" : "white",
                                borderRadius: "0",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            ประเมินความพอใจ
                        </Button>
                        <Button
                            onClick={() => handleTabChange(4)}
                            sx={{
                                color: tabActive === 4 ? "white" : "#002B38",
                                backgroundColor: tabActive === 4 ? "#002B38" : "white",
                                borderRadius: "0",
                                borderTopRightRadius: ".5rem",
                                padding: ".75rem 2rem",
                                "&:hover": {
                                    color: "white",
                                    backgroundColor: "#002B38",
                                },
                            }}
                        >
                            จัดการของรางวัล
                        </Button>
                    </Box>

                    {tabActive === 1 && <AdminTabOne />}
                    {tabActive === 2 && <AdminTabTwo />}
                    {tabActive === 3 && <AdminTabThree />}
                    {tabActive === 4 && <AdminTabFour />}
                </Box>
            </Box>
        </>
    );
};

export default AdminPage;
