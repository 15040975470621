import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Box, Link } from "@mui/material";
import { Pagination } from "swiper/modules";
import { useCallback, useEffect, useState } from "react";
import { useLineContext } from "../../../hooks/useLineContext";
import { baseURL } from "../../../api/url";

import "swiper/css";
import "swiper/css/pagination";

const MemberSwipe = () => {
    const { liffToken } = useLineContext();
    const [data, setData] = useState([]);

    const fetchUserInterest = useCallback(async () => {
        try {
            if (liffToken) {
                const response = await axios.get(`${baseURL}/list_interest`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                });

                if (response.data.Code === "20000") {
                    setData(response.data.Data);
                }
            }
        } catch (e) {
            console.error(e);
        }
    }, [liffToken]);

    useEffect(() => {
        fetchUserInterest();
    }, [fetchUserInterest]);

    return (
        <Swiper
            className="member"
            spaceBetween={20}
            slidesPerView={1}
            breakpoints={{
                767: {
                    slidesPerView: data.length > 1 ? 2 : 1,
                },
            }}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            style={{ margin: "20px 0" }}
        >
            {data.map((item) => (
                <SwiperSlide key={item.index}>
                    <Link href={item.external_url} target="_blank" sx={{ position: "relative" }}>
                        <Box
                            component="img"
                            src={item.image_url}
                            alt="image"
                            sx={{ width: "100%", maxWidth: "450px", display: "block", margin: "0 auto" }}
                        />
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default MemberSwipe;
