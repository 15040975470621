import { Box, Link } from "@mui/material";
import arrowRightGradient from "../../../assets/icon/arrow-right-gradient.svg";

const PrivilegeItem = (props) => {
    const { img, url } = props;

    return (
        <Link href={url} target="_blank" sx={{ position: "relative" }}>
            <Box sx={{ borderRadius: "10px" }}>
                <Box component="img" src={img} alt="banner" sx={{ width: "100%", borderRadius: "10px" }} />
            </Box>
            <Box
                component="img"
                src={arrowRightGradient}
                sx={{ width: "15px", position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}
            />
        </Link>
    );
};

export default PrivilegeItem;
