import { Helmet } from "react-helmet-async";
import { Box, Container } from "@mui/material";
import MemberCard from "./components/MemberCard";
import bgStar from "../../assets/images/bg/bg-star.png";
import caltexCard from "../../assets/images/pages/member/member-card.png";
import MemberSwipe from "./components/MemberSwipe";

const MemberPage = () => {
    return (
        <>
            <Helmet>
                <title>Member | Caltex StarCard</title>
            </Helmet>
            <Box
                component="main"
                maxWidth="md"
                sx={{
                    width: "100%",
                    paddingBottom: "3.57rem",
                    margin: "0 auto",
                    minHeight: "calc(100vh - 60px)",
                    background: `url(${bgStar}) no-repeat center center / cover fixed, #cceffd`,
                    overflow: "hidden",
                }}
            >
                <Container component="section" maxWidth="md" sx={{ padding: "3.5rem 1.25rem" }}>
                    <Box display="flex" justifyContent="center">
                        <img
                            src={caltexCard}
                            alt="Caltex Card"
                            style={{ width: "95%", maxWidth: "550px", display: "block", margin: "1rem auto" }}
                        />
                    </Box>
                    <MemberCard />
                    <MemberSwipe />
                </Container>
            </Box>
        </>
    );
};

export default MemberPage;
