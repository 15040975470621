import { Box, MenuItem, Select, styled, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "../../hooks/useAppContext";
import { useLineContext } from "../../hooks/useLineContext";
import { baseURL } from "../../api/url";
import axios from "axios";
import * as Yup from "yup";
import InputField from "../../components/ui/InputField";
import RedButton from "../../components/ui/RedButton";
import bgStar from "../../assets/images/bg/bg-star.png";
import _ from "lodash";
import { useEffect, useState } from "react";

const FormLabel = styled("label")({
    display: "block",
    fontSize: "1rem",
    fontWeight: "500",
    color: "#004b61",
    marginBottom: ".5rem",
});

const AddressAddPage = () => {
    const { liffToken } = useLineContext();
    const { userInfo, setCompanyInfo, provinces, amphures, tambons } = useAppContext();
    const [filteredAmphures, setFilteredAmphures] = useState([]);
    const [filteredTambons, setFilteredTambons] = useState([]);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            fullname: "",
            phone: "",
            address: "",
            alley: "",
            street: "",
            province: "",
            district: "",
            subdistrict: "",
            zipcode: "",
        },
        validationSchema: Yup.object({
            fullname: Yup.string().required("โปรดระบุชื่อ - นามสกุลให้ถูกต้อง"),
            phone: Yup.string()
                .matches(/^[!0]/, "โปรดระบุเบอร์โทรศัพท์ให้ถูกต้อง")
                .required("โปรดระบุเบอร์โทรศัพท์ให้ถูกต้อง"),
            address: Yup.string().required("โปรดระบุที่อยู่ให้ถูกต้อง"),
            province: Yup.string().required("โปรดระบุจังหวัดให้ถูกต้อง"),
            district: Yup.string().required("โปรดระบุอำเภอให้ถูกต้อง"),
            subdistrict: Yup.string().required("โปรดระบุตำบลให้ถูกต้อง"),
            zipcode: Yup.string()
                .min(5, "โปรดระบุรหัสไปรษณีย์อย่างน้อย 5 ตัว")
                .required("โปรดระบุรหัสไปรษณีย์ให้ถูกต้อง"),
        }),
        onSubmit: (values) => {
            axios
                .post(
                    `${baseURL}/company_add_address`,
                    {
                        code_company: userInfo.code,
                        address: {
                            name: values.fullname,
                            phonenumber: values.phone,
                            detail: values.address,
                            alley: values.alley,
                            street_name: values.street,
                            province: values.province,
                            district: values.district,
                            subdistrict: values.subdistrict,
                            zip_code: values.zipcode,
                        },
                    },
                    {
                        headers: { Authorization: `Bearer ${liffToken}` },
                    }
                )
                .then((res) => {
                    if (res.data.Code === "20000") {
                        values.fullname = "";
                        values.phone = "";
                        values.address = "";
                        values.alley = "";
                        values.street = "";
                        values.province = "";
                        values.district = "";
                        values.subdistrict = "";
                        values.zipcode = "";
                        setCompanyInfo((prev) => ({ ...prev, ...res.data.Data }));
                        navigate(-1);
                    }
                })
                .catch((e) => {
                    throw new Error("Can't add company addresss", e);
                });
        },
    });

    const onProvinceChange = (value) => {
        const filteredProvince = _.find(provinces, { name_th: value });
        const newAmphures = _.filter(amphures, { province_id: filteredProvince.id });
        setFilteredAmphures(newAmphures);
        formik.setFieldValue("province", value);
        formik.setFieldValue("district", "");
        formik.setFieldValue("subdistrict", "");
        formik.setFieldValue("zipcode", "");
    };

    const onAmphureChange = (value) => {
        const filteredAmphure = _.find(amphures, { name_th: value });
        const newTambons = _.filter(tambons, { amphure_id: filteredAmphure.id });
        setFilteredTambons(newTambons);
        formik.setFieldValue("district", value);
        formik.setFieldValue("subdistrict", "");
        formik.setFieldValue("zipcode", "");
    };

    const onTambonChange = (value) => {
        formik.setFieldValue("subdistrict", value);
    };

    useEffect(() => {
        if (formik.values.subdistrict) {
            const tambon = _.find(filteredTambons, { name_th: formik.values.subdistrict });
            formik.setFieldValue("zipcode", tambon.zip_code);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.subdistrict]);

    return (
        <>
            <Helmet>
                <title>Add address | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                maxWidth="md"
                mx="auto"
                sx={{
                    height: "calc(100vh - 78px)",
                    overflow: "auto",
                    background: `url(${bgStar}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" sx={{ padding: "6rem 1.25rem" }}>
                    <Box
                        sx={(theme) => ({
                            padding: "2rem 1.5rem",
                            borderRadius: "20px",
                            background: "linear-gradient(90deg, #E3EFFF, #FFFFFF)",
                            [theme.breakpoints.up("sm")]: {
                                padding: "2rem 4rem",
                            },
                        })}
                    >
                        <Typography
                            sx={{ fontSize: "1.5rem", fontWeight: "500", color: "#004B61", marginBottom: "1.25rem" }}
                        >
                            เพิ่มที่อยู่ใหม่
                        </Typography>
                        <form onSubmit={formik.handleSubmit}>
                            <Box
                                sx={(theme) => ({
                                    [theme.breakpoints.up("sm")]: {
                                        display: "grid",
                                        gridTemplateColumns: "1fr 1fr",
                                        columnGap: "20px",
                                    },
                                })}
                            >
                                <Box mb={2}>
                                    <FormLabel>ชื่อ - นามสกุล</FormLabel>
                                    <InputField
                                        fullWidth
                                        id="fullname"
                                        name="fullname"
                                        value={formik.values.fullname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                borderRadius: "10px",
                                                outline:
                                                    formik.touched.fullname && formik.errors.fullname
                                                        ? "2px solid #ED1C24"
                                                        : "none",
                                            },
                                            "& .MuiInputBase-root .MuiOutlinedInput-input": {
                                                padding: "0 1rem",
                                            },
                                        }}
                                    />
                                    {formik.touched.fullname && formik.errors.fullname && (
                                        <Typography sx={{ fontSize: ".85rem", padding: "0.5rem", color: "#ED1C24" }}>
                                            {formik.errors.fullname}
                                        </Typography>
                                    )}
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>เบอร์โทรศัพท์</FormLabel>
                                    <InputField
                                        fullWidth
                                        id="phone"
                                        name="phone"
                                        value={formik.values.phone}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        inputProps={{
                                            maxLength: "10",
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                        }}
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                borderRadius: "10px",
                                                outline:
                                                    formik.touched.phone && formik.errors.phone
                                                        ? "2px solid #ED1C24"
                                                        : "none",
                                            },
                                            "& .MuiInputBase-root .MuiOutlinedInput-input": {
                                                padding: "0 1rem",
                                            },
                                        }}
                                    />
                                    {formik.touched.phone && formik.errors.phone && (
                                        <Typography sx={{ fontSize: ".85rem", padding: "0.5rem", color: "#ED1C24" }}>
                                            {formik.errors.phone}
                                        </Typography>
                                    )}
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>บ้านเลขที่ / หมู่บ้าน</FormLabel>
                                    <InputField
                                        fullWidth
                                        id="address"
                                        name="address"
                                        multiline
                                        minRows={3}
                                        value={formik.values.address}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                borderRadius: "10px",
                                                outline:
                                                    formik.touched.address && formik.errors.address
                                                        ? "2px solid #ED1C24"
                                                        : "none",
                                            },
                                            "& .MuiInputBase-root .MuiInputBase-inputMultiline": {
                                                padding: "0 .25rem",
                                            },
                                        }}
                                    />
                                    {formik.touched.address && formik.errors.address && (
                                        <Typography sx={{ fontSize: ".85rem", padding: "0.5rem", color: "#ED1C24" }}>
                                            {formik.errors.address}
                                        </Typography>
                                    )}
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>ซอย</FormLabel>
                                    <InputField
                                        fullWidth
                                        id="alley"
                                        name="alley"
                                        value={formik.values.alley}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                borderRadius: "10px",
                                                outline:
                                                    formik.touched.alley && formik.errors.alley
                                                        ? "2px solid #ED1C24"
                                                        : "none",
                                            },
                                            "& .MuiInputBase-root .MuiOutlinedInput-input": {
                                                padding: "0 1rem",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>ถนน</FormLabel>
                                    <InputField
                                        fullWidth
                                        id="street"
                                        name="street"
                                        value={formik.values.street}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                borderRadius: "10px",
                                                outline:
                                                    formik.touched.street && formik.errors.street
                                                        ? "2px solid #ED1C24"
                                                        : "none",
                                            },
                                            "& .MuiInputBase-root .MuiOutlinedInput-input": {
                                                padding: "0 1rem",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>จังหวัด</FormLabel>
                                    <Select
                                        id="province"
                                        name="province"
                                        displayEmpty
                                        fullWidth
                                        value={formik.values.province || ""}
                                        onChange={(e) => onProvinceChange(e.target.value)}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return (
                                                    <Typography sx={{ color: "transparent" }}>ไม่พบจังหวัด</Typography>
                                                );
                                            }

                                            return selected;
                                        }}
                                        sx={{
                                            borderRadius: "10px",
                                            backgroundColor: "white",
                                            outline:
                                                formik.touched.province && formik.errors.province
                                                    ? "2px solid #ED1C24"
                                                    : "none",
                                        }}
                                    >
                                        {provinces.length > 0 ? (
                                            provinces.map((item) => {
                                                return (
                                                    <MenuItem key={item.id} value={item.name_th}>
                                                        {item.name_th}
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <MenuItem disabled value="">
                                                ไม่พบจังหวัด
                                            </MenuItem>
                                        )}
                                    </Select>
                                    {formik.touched.province && formik.errors.province && (
                                        <Typography sx={{ fontSize: ".85rem", padding: "0.5rem", color: "#ED1C24" }}>
                                            {formik.errors.province}
                                        </Typography>
                                    )}
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>อำเภอ</FormLabel>
                                    <Select
                                        id="district"
                                        name="district"
                                        displayEmpty
                                        fullWidth
                                        value={formik.values.district || ""}
                                        onChange={(e) => onAmphureChange(e.target.value)}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return (
                                                    <Typography sx={{ color: "transparent" }}>
                                                        กรุณาเลือกจังหวัดก่อน
                                                    </Typography>
                                                );
                                            }

                                            return selected;
                                        }}
                                        sx={{
                                            borderRadius: "10px",
                                            backgroundColor: "white",
                                            outline:
                                                formik.touched.district && formik.errors.district
                                                    ? "2px solid #ED1C24"
                                                    : "none",
                                        }}
                                    >
                                        {filteredAmphures.length > 0 ? (
                                            filteredAmphures.map((item) => {
                                                return (
                                                    <MenuItem key={item.id} value={item.name_th}>
                                                        {item.name_th}
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <MenuItem disabled value="">
                                                กรุณาเลือกจังหวัดก่อน
                                            </MenuItem>
                                        )}
                                    </Select>
                                    {formik.touched.district && formik.errors.district && (
                                        <Typography sx={{ fontSize: ".85rem", padding: "0.5rem", color: "#ED1C24" }}>
                                            {formik.errors.district}
                                        </Typography>
                                    )}
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>ตำบล</FormLabel>
                                    <Select
                                        id="tambon"
                                        name="tambon"
                                        displayEmpty
                                        fullWidth
                                        value={formik.values.subdistrict || ""}
                                        onChange={(e) => onTambonChange(e.target.value)}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return (
                                                    <Typography sx={{ color: "transparent" }}>
                                                        กรุณาเลือกอำเภอก่อน
                                                    </Typography>
                                                );
                                            }

                                            return selected;
                                        }}
                                        sx={{
                                            borderRadius: "10px",
                                            backgroundColor: "white",
                                            outline:
                                                formik.touched.subdistrict && formik.errors.subdistrict
                                                    ? "2px solid #ED1C24"
                                                    : "none",
                                        }}
                                    >
                                        {filteredTambons.length > 0 ? (
                                            filteredTambons.map((item) => {
                                                return (
                                                    <MenuItem key={item.id} value={item.name_th}>
                                                        {item.name_th}
                                                    </MenuItem>
                                                );
                                            })
                                        ) : (
                                            <MenuItem disabled value="">
                                                กรุณาเลือกอำเภอก่อน
                                            </MenuItem>
                                        )}
                                    </Select>
                                    {formik.touched.subdistrict && formik.errors.subdistrict && (
                                        <Typography sx={{ fontSize: ".85rem", padding: "0.5rem", color: "#ED1C24" }}>
                                            {formik.errors.subdistrict}
                                        </Typography>
                                    )}
                                </Box>
                                <Box mb={2}>
                                    <FormLabel>รหัสไปรษณีย์</FormLabel>
                                    <InputField
                                        fullWidth
                                        id="zipcode"
                                        name="zipcode"
                                        value={formik.values.zipcode || ""}
                                        inputProps={{
                                            maxLength: "5",
                                            inputMode: "numeric",
                                            pattern: "[0-9]*",
                                            readOnly: true,
                                        }}
                                        sx={{
                                            "& .MuiInputBase-root.MuiOutlinedInput-root": {
                                                borderRadius: "10px",
                                                outline:
                                                    formik.touched.zipcode && formik.errors.zipcode
                                                        ? "2px solid #ED1C24"
                                                        : "none",
                                            },
                                            "& .MuiInputBase-root .MuiOutlinedInput-input": {
                                                padding: "0 1rem",
                                            },
                                        }}
                                    />
                                    {formik.touched.zipcode && formik.errors.zipcode && (
                                        <Typography sx={{ fontSize: ".85rem", padding: "0.5rem", color: "#ED1C24" }}>
                                            {formik.errors.zipcode}
                                        </Typography>
                                    )}
                                </Box>
                                <Box
                                    mt={5}
                                    mb={3}
                                    sx={(theme) => ({
                                        [theme.breakpoints.up("sm")]: {
                                            gridColumn: "span 2",
                                            width: "250px",
                                            margin: "40px auto 24px",
                                        },
                                    })}
                                >
                                    <RedButton fullWidth type="submit">
                                        ส่ง
                                    </RedButton>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AddressAddPage;
