import { Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@mui/material";
import { theme } from "./assets/js/theme";
import LineContextProvider from "./context/LineContext";
import AppContextProvider from "./context/AppContext";
import Layout from "./layouts/Layout";
import HomePage from "./pages/homepage/HomePage";
import NotFoundPage from "./pages/NotFountPage";
import RegisterPage from "./pages/register/RegisterPage";
import MemberPage from "./pages/member/MemberPage";
import RewardPage from "./pages/reward/RewardPage";
import RewardDetailPage from "./pages/reward/RewardDetailPage";
import PrivilegePage from "./pages/privilege/PrivilegePage";
import PrivilegeDetailPage from "./pages/privilege/PrivilegeDetailPage";
import HistoryPage from "./pages/history/HistoryPage";
import RedirectPage from "./pages/redirect/RedirectPage";
import AdressListPage from "./pages/address/AddressListPage";
import AddressAddPage from "./pages/address/AddressAddPage";
import AddressEditPage from "./pages/address/AddressEditPage";
import AdminListPage from "./pages/admin/AdminListPage";
import AdminHistoryPage from "./pages/admin/AdminHistoryPage";
import ShoppingCartPage from "./pages/shoppingCart/ShoppingCartPage";
import EvaluationPage from "./pages/evaluation/EvaluationPage";
import EvaluationThankyouPage from "./pages/evaluation/ThankyouPage";
import AdminRewardDetailPage from "./pages/admin/AdminRewardDetailPage";

const App = () => {
    return (
        <>
            <LineContextProvider>
                <AppContextProvider>
                    <HelmetProvider>
                        <ThemeProvider theme={theme}>
                            <Layout>
                                <Routes>
                                    <Route exact path="/" element={<RedirectPage />} />
                                    <Route path="/home" element={<HomePage />} />
                                    <Route path="/register" element={<RegisterPage />} />
                                    <Route path="/member" element={<MemberPage />} />
                                    <Route path="/reward" element={<RewardPage />} />
                                    <Route path="/reward/:rewardId" element={<RewardDetailPage />} />
                                    <Route path="/privilege" element={<PrivilegePage />} />
                                    <Route path="/privilege/:privilegeId" element={<PrivilegeDetailPage />} />
                                    <Route path="/history" element={<HistoryPage />} />
                                    <Route path="/address" element={<AdressListPage />}/>
                                    <Route path="/address/add" element={<AddressAddPage />}/>
                                    <Route path="/address/edit/:addressId" element={<AddressEditPage />}/>
                                    <Route path="/admin" element={<AdminListPage />}/>
                                    <Route path="/admin/:id" element={<AdminHistoryPage />}/>
                                    <Route path="/admin/reward/:id" element={<AdminRewardDetailPage />}/>
                                    <Route path="/shopping" element={<ShoppingCartPage />}/>
                                    <Route path="/evaluate_satisfaction/:evaluateId" element={<EvaluationPage />}/>
                                    <Route path="/evaluate_satisfaction/thankyou" element={<EvaluationThankyouPage />}/>
                                    <Route path="*" element={<NotFoundPage />} />
                                </Routes>
                            </Layout>
                        </ThemeProvider>
                    </HelmetProvider>
                </AppContextProvider>
            </LineContextProvider>
        </>
    );
};

export default App;
