import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useAppContext } from "../../hooks/useAppContext";
import { useLineContext } from "../../hooks/useLineContext";
import { baseURL } from "../../api/url";
import _ from "lodash";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RedButton from "../../components/ui/RedButton";
import bgStar from "../../assets/images/bg/bg-star.png";
import starRedIcon from "../../assets/icon/star-red.svg";
import giftBox from "../../assets/icon/giftbox-gradient.svg";
import caltexStar from "../../assets/icon/caltex-red.svg";
import DialogConfirm from "../../components/ui/DialogConfirm";
import DialogInfo from "../../components/ui/DialogInfo";

const RewardDetailPage = () => {
    const { rewardId } = useParams();
    const { liffToken, lineProfile } = useLineContext();
    const { userInfo, companyInfo } = useAppContext();
    const [listReward, setListReward] = useState([]);
    const [currentReward, setCurrentReward] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogState, setDialogState] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const navigate = useNavigate();

    const imageURL = currentReward?.image[0] ? currentReward?.image[0] : "";

    const onOpenDialog = () => {
        if (companyInfo?.state !== "ACCOUNT TERMINATE") {
            setDialogState(false);

            if (companyInfo?.address?.length <= 0) {
                navigate("/address/add");
            } else {
                setDialogOpen(true);
            }
        } else {
            setDialogState(true);
        }
    };

    const onCloseDialog = () => {
        setDialogOpen(false);
    };

    useEffect(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/listreward`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        setListReward(res.data.Data);
                    }
                })
                .catch((e) => {
                    throw new Error("Can't get list of reward", e);
                });
        }
    }, [liffToken]);

    useEffect(() => {
        const reward = _.find(listReward, { _id: rewardId });

        if (reward?.stock === 0) {
            setIsEmpty(true);
        } else {
            setIsEmpty(false);
        }

        setCurrentReward(reward);
    }, [listReward, rewardId]);

    useEffect(() => {
        const filtered = _.find(companyInfo.user, { userId: lineProfile.userId });
        if (filtered) {
            setIsOwner(filtered.owner);
        }
    }, [companyInfo, lineProfile]);

    return (
        <>
            <Helmet>
                <title>{currentReward?.name || "Reward Detail"} | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                maxWidth="md"
                mx="auto"
                sx={{ background: `url(${bgStar}) no-repeat center center / cover fixed` }}
            >
                <Box component="section" sx={{ padding: "60px 0 120px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: ".5rem 1.5rem .5rem 2rem",
                        }}
                    >
                        <Box sx={{ fontSize: "1.5rem", fontWeight: "500", color: "#004B61" }}>Reward</Box>
                        <Box>
                            <Box sx={{ fontSize: "1rem", fontWeight: "500", color: "#FF0000", textAlign: "right" }}>
                                คะแนนสะสม
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "5px",
                                    fontSize: "1.75rem",
                                    lineHeight: "1.75rem",
                                    fontWeight: "500",
                                    color: "#FF0000",
                                }}
                            >
                                <Box component="img" src={starRedIcon} alt="Star" sx={{ height: "22px" }} />
                                {userInfo?.accumulated_points || "0"}
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {!imageURL && <CircularProgress />}
                        {imageURL && <Box component="img" src={imageURL} alt="Reward" sx={{ height: "190px" }} />}
                    </Box>
                    <Box py={2}>
                        <Grid container px={2}>
                            {/* Point */}
                            <Grid
                                item
                                sx={{
                                    minHeight: "60px",
                                    background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                    border: "2px solid white",
                                    borderRadius: "15px",
                                    padding: ".5rem",
                                    marginBottom: ".5rem",
                                }}
                                xs={12}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid
                                        item
                                        xs={3}
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <img
                                            src={giftBox}
                                            alt="GiftBox"
                                            style={{ height: "50px", display: "block", margin: "0 auto -5px" }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                color: "#004B61",
                                                textAlign: "center",
                                            }}
                                        >
                                            Reward
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" alignItems="center" gap={1}>
                                        <Box
                                            component="img"
                                            src={caltexStar}
                                            alt="Caltex Point"
                                            sx={{ height: "20px", marginTop: "2px" }}
                                        />
                                        <Typography
                                            sx={{
                                                fontSize: "1.75rem",
                                                fontWeight: "700",
                                                color: "#FD0004",
                                            }}
                                        >
                                            {currentReward?.point || "-"}
                                        </Typography>
                                        <Box
                                            component="span"
                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#FD0004" }}
                                        >
                                            คะแนน
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Reward name */}
                            <Grid
                                item
                                display="flex"
                                alignItems="center"
                                sx={{
                                    minHeight: "60px",
                                    background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                    border: "2px solid white",
                                    borderRadius: "15px",
                                    padding: ".5rem",
                                    marginBottom: ".5rem",
                                }}
                                xs={12}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                color: "#004B61",
                                            }}
                                        >
                                            รางวัล
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                lineHeight: "1.5rem",
                                                color: "#FD0004",
                                                letterSpacing: "0.05rem",
                                            }}
                                        >
                                            {currentReward?.name || "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Reward detail */}
                            <Grid
                                item
                                display="flex"
                                alignItems="center"
                                sx={{
                                    minHeight: "60px",
                                    background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                    border: "2px solid white",
                                    borderRadius: "15px",
                                    padding: ".5rem",
                                    marginBottom: ".5rem",
                                }}
                                xs={12}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                color: "#004B61",
                                            }}
                                        >
                                            รายละเอียด
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                lineHeight: "1.5rem",
                                                color: "#FD0004",
                                                letterSpacing: "0.05rem",
                                            }}
                                        >
                                            {currentReward?.detail || "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Quantity */}
                            <Grid
                                item
                                display="flex"
                                alignItems="center"
                                sx={{
                                    minHeight: "60px",
                                    background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                    border: "2px solid white",
                                    borderRadius: "15px",
                                    padding: ".5rem",
                                    marginBottom: ".5rem",
                                }}
                                xs={12}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                color: "#004B61",
                                            }}
                                        >
                                            จำนวน
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                lineHeight: "1.5rem",
                                                color: "#FD0004",
                                            }}
                                        >
                                            {currentReward?.quantity || "-"}&nbsp;&nbsp;
                                            <Box component="span">{currentReward?.unit || "ชิ้น"}</Box>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* Value */}
                            <Grid
                                item
                                display="flex"
                                alignItems="center"
                                sx={{
                                    minHeight: "60px",
                                    background: "linear-gradient(90deg, #C2DCFF, #FFFFFF)",
                                    border: "2px solid white",
                                    borderRadius: "15px",
                                    padding: ".5rem",
                                    marginBottom: ".5rem",
                                }}
                                xs={12}
                            >
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={3} display="flex" justifyContent="center" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                textAlign: "center",
                                                color: "#004B61",
                                            }}
                                        >
                                            มูลค่า
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={9} display="flex" alignItems="center">
                                        <Typography
                                            sx={{
                                                fontSize: "1rem",
                                                fontWeight: "500",
                                                lineHeight: "1.5rem",
                                                color: "#FD0004",
                                            }}
                                        >
                                            {typeof currentReward?.value === "number"
                                                ? String(currentReward?.value).replace(
                                                      /\d{1,3}(?=(\d{3})+(?!\d))/g,
                                                      "$&,"
                                                  )
                                                : "-"}{" "}
                                            บาท
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box p={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography sx={{ fontSize: ".75rem", color: "#004B61", textAlign: "center" }}>
                                    ของรางวัลอาจมีการเปลี่ยนแปลง สี หรือ รุ่น ในกรณีที่ของรางวัลหมด / เลิกผลิต
                                    เป็นรุ่นใกล้เคียงหรือเทียบเท่า
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to="/reward" style={{ textDecoration: "none" }}>
                                    <Button
                                        variant="contained"
                                        startIcon={<ArrowBackIosIcon />}
                                        sx={{
                                            width: "100px",
                                            height: "44px",
                                            borderRadius: "15px",
                                            background: "linear-gradient(0deg, #365CA0, #264373, #152741)",
                                            fontSize: "1rem",
                                        }}
                                    >
                                        กลับ
                                    </Button>
                                </Link>
                            </Grid>
                            <Grid item xs={6}>
                                <RedButton fullWidth disabled={!isOwner || isEmpty} onClick={onOpenDialog}>
                                    {isOwner && isEmpty ? "สิทธิ์รอบนี้เต็มแล้ว" : "เพิ่มลงตะกร้า"}
                                </RedButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>

            {/* <DialogInfo
                isOpen={dialogOpen}
                onClose={onCloseDialog}
                text={`เริ่มแลกรางวัลได้ตั้งแต่ \n20 เมษายน 2566 - 10 ธันวาคม 2566`}
                btnText="ปิด"
            /> */}

            <DialogInfo
                isOpen={dialogState}
                onClose={() => setDialogState(false)}
                text={`กรุณาติดต่อ\nฝ่ายขายแผนกบัตร\nคาลเท็กซ์สตาร์การ์ด`}
                btnText="ปิด"
            />
            <DialogConfirm open={dialogOpen} onClose={onCloseDialog} reward={currentReward} />
        </>
    );
};

export default RewardDetailPage;
