import _ from "lodash";
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Link, Typography } from "@mui/material";
import axios from "axios";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InputField from "../../../components/ui/InputField";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { baseURL } from "../../../api/url";
import { useLineContext } from "../../../hooks/useLineContext";
import { useAppContext } from "../../../hooks/useAppContext";
import DialogLoading from "../../../components/ui/DialogLoading";
import Footer from "../../../layouts/Footer";
import GiftBox from "../../../assets/icon/giftbox-gradient.svg";

const RegisterForm = (props) => {
    const { liffToken } = useLineContext();
    const { companyInfo, setCompanyInfo, setUserInfo } = useAppContext();
    const nagivate = useNavigate();
    const [companyCode, setCompanyCode] = useState("");
    const [acceptConsent, setAcceptConsent] = useState(false);
    const [dialogLoadingModal, setDialogLoadingModal] = useState(false);
    const peopleNameRef = useRef(null);

    const sendRegister = async () => {
        const peopleNameValue = peopleNameRef?.current?.value;
        setDialogLoadingModal(true);

        try {
            const response = await axios.post(
                `${baseURL}/register`,
                {
                    code_company: companyCode,
                    people_name: peopleNameValue,
                    phonenumber: props.phoneNumber,
                },
                {
                    headers: { Authorization: `Bearer ${liffToken}` },
                }
            );

            if (response.data.Code === "20000" || response.data.Code === "20001") {
                axios
                    .get(`${baseURL}/userinfo`, {
                        headers: { Authorization: `Bearer ${liffToken}` },
                    })
                    .then((res) => {
                        setDialogLoadingModal(false);
                        nagivate("/home");
                        if (res.data.Code === "20000") {
                            setUserInfo({
                                ...res.data.Data,
                                accumulated_points: res.data.Data.accumulated_points.toFixed(2),
                                spending_amount: res.data.Data.spending_amount.toFixed(2),
                                spending_criteria: res.data.Data.spending_criteria.toFixed(2),
                            });
                        }
                    })
                    .catch((e) => {
                        setDialogLoadingModal(false);
                        console.error(e);
                    });
            }
        } catch (e) {
            setDialogLoadingModal(false);
            throw new Error("Can't register", e);
        }
    };

    const fetchCompanyInfo = async (code) => {
        try {
            const response = await axios.get(`${baseURL}/companyinfo`, {
                headers: { Authorization: `Bearer ${liffToken}` },
                params: { code_company: code },
            });

            if (response.data.Code === "20000") {
                setCompanyInfo({ ...response?.data?.Data });
            }
        } catch (e) {
            throw new Error("Can't get company info", e);
        }
    };

    const onCompanyCodeChange = (e) => {
        const value = e.target.value;

        if (_.size(value) === 10) {
            fetchCompanyInfo(value);
        }

        setCompanyCode(value);
    };

    const onAcceptConsentChange = (e) => {
        setAcceptConsent(e.target.checked);
    };

    const onRegisterSubmit = (e) => {
        e.preventDefault();
        const peopleNameValue = peopleNameRef?.current?.value;

        if (companyInfo.user_id) {
            if (companyCode && acceptConsent) sendRegister();
        } else {
            if (companyCode && acceptConsent && peopleNameValue) sendRegister();
        }
    };

    return (
        <Container maxWidth="lg" sx={{ minHeight: "calc(100vh - 272px)" }}>
            <Typography
                component="h1"
                sx={{
                    fontSize: "1.75rem",
                    fontWeight: "normal",
                    color: "#004B61",
                    textAlign: "center",
                    marginBottom: "2.5rem",
                }}
            >
                โปรด
                <Box component="span" sx={{ fontWeight: "bold" }}>
                    ยืนยันข้อมูล
                </Box>
                ของท่าน
            </Typography>

            <Grid
                container
                sx={{
                    padding: "2.5rem 1rem",
                    borderRadius: "20px",
                    background: `linear-gradient(180deg, #006B7D, #005D76, #00475B), #006B7D`,
                    position: "relative",
                    marginBottom: "1rem"
                }}
            >
                <Grid item xs={12}>
                    <form onSubmit={onRegisterSubmit}>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "1.25rem" }}>
                            <InputField
                                fullWidth
                                placeholder="รหัสบริษัท"
                                value={companyCode || ""}
                                inputProps={{ maxLength: "10", inputMode: "numeric", pattern: "[0-9]*" }}
                                onChange={onCompanyCodeChange}
                            />
                            <InputField
                                fullWidth
                                placeholder="ชื่อบริษัท"
                                value={companyInfo.company_name || ""}
                                disabled
                            />
                            {Object.keys(companyInfo).length > 0 && !companyInfo.user_id && (
                                <InputField inputRef={peopleNameRef} fullWidth placeholder="ชื่อ-นามสกุล ผู้มีอำนาจ" />
                            )}
                            <Box sx={{ marginTop: "3rem" }}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="consent"
                                                checked={acceptConsent}
                                                onChange={onAcceptConsentChange}
                                                sx={{
                                                    color: "white",
                                                    "&.Mui-checked": {
                                                        color: "white",
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            <span>
                                                ฉันยอมรับ
                                                <Link
                                                    href="https://s3.starcardclub.com/starcardclub/%E0%B8%99%E0%B9%82%E0%B8%A2%E0%B8%9A%E0%B8%B2%E0%B8%A2%E0%B9%80%E0%B8%81%E0%B8%B5%E0%B9%88%E0%B8%A2%E0%B8%A7%E0%B8%81%E0%B8%B1%E0%B8%9A%E0%B8%81%E0%B8%B2%E0%B8%A3%E0%B8%84%E0%B8%B8%E0%B9%89%E0%B8%A1%E0%B8%84%E0%B8%A3%E0%B8%AD%E0%B8%87%E0%B8%82%E0%B9%89%E0%B8%AD%E0%B8%A1%E0%B8%B9%E0%B8%A5%E0%B8%AA%E0%B9%88%E0%B8%A7%E0%B8%99%E0%B8%9A%E0%B8%B8%E0%B8%84.pdf"
                                                    target="_blank"
                                                    rel="noopener"
                                                    underline="always"
                                                    sx={{ textDecorationColor: "white" }}
                                                >
                                                    เงื่อนไขการใช้บริการและนโยบายความเป็นส่วนตัว
                                                </Link>
                                            </span>
                                        }
                                        sx={{
                                            "&.MuiFormControlLabel-root": {
                                                margin: "0",
                                                gap: ".25rem",
                                            },
                                            "& .MuiTypography-root": {
                                                fontSize: "0.7rem",
                                                color: "white",
                                            },
                                        }}
                                    />
                                </FormGroup>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled={!acceptConsent}
                                    endIcon={<ChevronRightIcon />}
                                    sx={{
                                        width: "100%",
                                        fontSize: "1rem",
                                        borderRadius: "15px",
                                        color: "white",
                                        backgroundColor: "#ED1C24",
                                        border: "2px solid #ED1C24",
                                        marginTop: ".5rem",
                                        "&:hover": {
                                            color: "#ED1C24",
                                            backgroundColor: "white",
                                            border: "2px solid #ED1C24",
                                        },
                                        "&.Mui-disabled": {
                                            color: "white",
                                            backgroundColor: "#ED1C24",
                                            border: "2px solid #ED1C24",
                                            opacity: ".75",
                                        },
                                    }}
                                >
                                    ลงทะเบียน
                                </Button>
                            </Box>
                        </Box>
                    </form>
                </Grid>
            </Grid>
            <Footer renderText icon={GiftBox} textColor="#004B61" iconStyle={{ width: "60px", height: "60px", marginTop: "27px" }} />

            <DialogLoading open={dialogLoadingModal} message="กำลังสมัครสมาชิก" />
        </Container>
    );
};

export default RegisterForm;
