import { useCallback, useEffect, useState } from "react";
import {
    Avatar,
    Box,
    CircularProgress,
    InputAdornment,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
} from "@mui/material";
import RedButton from "../../../components/ui/RedButton";
import DataTable from "react-data-table-component";
import SearchIcon from "@mui/icons-material/Search";
import DialogSuccess from "../../../components/ui/DialogSuccess";
import DialogLoading from "../../../components/ui/DialogLoading";
import dayjs from "dayjs";
import axios from "axios";
import { baseURL } from "../../../api/url";
import { useLineContext } from "../../../hooks/useLineContext";

const customStyles = {
    headRow: {
        style: {
            fontSize: ".75rem",
            color: "white",
            background: "linear-gradient(180deg, #006B7D, #005D76, #00475B)",
        },
    },
    headCells: {
        style: {
            justifyContent: "center",
            borderLeft: "1px solid white",
        },
    },
    rows: {
        style: {
            background: "#E7FBFF",
            cursor: "pointer",
            "&:not(:last-of-type)": {
                borderBottomColor: "white",
            },
        },
    },
    cells: {
        style: {
            padding: ".5rem",
            "& div:first-child": {
                whiteSpace: "pre-wrap !important",
                overflow: "auto !important",
                textOverflow: "unset !important",
            },
        },
    },
};

const customStyles3 = {
    ...customStyles,
    headCells: {
        style: {
            justifyContent: "center",
            "&:not(:first-child)": {
                borderLeft: "1px solid white",
            },
        },
    },
};

const AdminTabThree = () => {
    const { liffToken } = useLineContext();
    const [isLoading3, setIsLoading3] = useState(false);
    const [tabActive3_in, setTabActive3_in] = useState(1);
    const [term3, setTerm3] = useState("");
    const [isSearchEvaluation, setIsSearchEvaluation] = useState(false);
    const [isSearchLineUser, setIsSearchLineUser] = useState(false);
    const [listEvaluation, setListEvaluation] = useState([]);
    const [listEvaluationFiltered, setListEvaluationFiltered] = useState([]);
    const [listLineUser, setListLineUser] = useState([]);
    const [listLineUserFiltered, setListLineUserFiltered] = useState([]);
    const [sendEvaluateLoading, setSendEvaluateLoading] = useState(false);
    const [sendEvaluateSuccess, setSendEvaluateSuccess] = useState(false);

    const columns3 = [
        {
            name: "ชื่อผู้รับ",
            width: "220px",
            sortable: true,
            cell: (row) => row.user_info.displayName || row.user_info.lineuserid || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
            },
        },
        {
            name: "ชื่อผู้ส่ง",
            width: "220px",
            sortable: true,
            cell: (row) => row.admin_info.name || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "คะแนน",
            width: "140px",
            sortable: true,
            cell: (row) => row.rating || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "ข้อเสนอแนะ",
            sortable: true,
            cell: (row) => row.comment || "-",
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
        {
            name: "Create Timestamp",
            cell: (row) => (row.create_timestamp ? dayjs(row.create_timestamp).format("DD/MM/YYYY HH:mm:ss") : "-"),
            style: {
                padding: "0 .5rem",
                textAlign: "center",
                justifyContent: "center",
                borderLeft: "1px solid white",
            },
        },
    ];

    const sendEvaluation = useCallback(
        async (userId) => {
            setSendEvaluateLoading(true);

            try {
                const response = await axios.post(
                    `${baseURL}/send_evaluation`,
                    { user_id: userId },
                    { headers: { Authorization: `Bearer ${liffToken}` } }
                );

                if (response) {
                    setSendEvaluateLoading(false);

                    if (response.data.Code === "20000") {
                        setSendEvaluateSuccess(true);
                    }
                }
            } catch (e) {
                setSendEvaluateSuccess(false);
                setSendEvaluateLoading(false);
                console.error(e);
            }
        },
        [liffToken]
    );

    const fetchListEvaluation = useCallback(async () => {
        setIsLoading3(true);
        try {
            const response = await axios.get(`${baseURL}/list_evaluation`, {
                headers: {
                    Authorization: `Bearer ${liffToken}`,
                },
            });

            if (response) {
                setIsLoading3(false);
                setListEvaluation(response.data.Data);
            }
        } catch (e) {
            setIsLoading3(false);
            console.error(e);
        }
    }, [liffToken]);

    const fetchListLineUser = useCallback(async () => {
        try {
            const response = await axios.get(`${baseURL}/list_user_evaluation`, {
                params: {
                    action: "query",
                },
                headers: {
                    Authorization: `Bearer ${liffToken}`,
                },
            });

            if (response) {
                setListLineUser(response.data.Data);
            }
        } catch (e) {
            console.error(e);
        }
    }, [liffToken]);

    const onSearchListEvaluation = useCallback(() => {
        setIsSearchEvaluation(true);

        const filtered = listEvaluation.filter((item) => {
            if (item.displayName) {
                return item.displayName.toLowerCase().includes(term3.toLowerCase());
            } else {
                return item.user_id.toLowerCase().includes(term3.toLowerCase());
            }
        });

        setListEvaluationFiltered(filtered);
    }, [listEvaluation, term3]);

    const onSearchListLineUser = useCallback(() => {
        setIsSearchLineUser(true);

        const filtered = listLineUser.filter((item) => {
            if (item.displayName) {
                return item.displayName.toLowerCase().includes(term3.toLowerCase());
            } else {
                return item.lineuserid.toLowerCase().includes(term3.toLowerCase());
            }
        });

        setListLineUserFiltered(filtered);
    }, [listLineUser, term3]);

    useEffect(() => {
        if (liffToken) {
            fetchListEvaluation();
            fetchListLineUser();
        }
    }, [liffToken, fetchListEvaluation, fetchListLineUser]);

    useEffect(() => {
        if (!term3) {
            setIsSearchEvaluation(false);
            setIsSearchLineUser(false);
        }
    }, [term3]);

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    padding: "1rem",
                    backgroundColor: "white",
                }}
            >
                <Box
                    onClick={() => setTabActive3_in(1)}
                    sx={{
                        margin: "0 0.5rem",
                        padding: "1rem",
                        fontSize: ".875rem",
                        backgroundColor: tabActive3_in === 1 ? "#002B38" : "white",
                        color: tabActive3_in === 1 ? "white" : "#002B38",
                        border: "1px solid #002B38",
                        cursor: "pointer",
                    }}
                >
                    รายการประเมินความพึงพอใจ
                </Box>
                <Box
                    onClick={() => setTabActive3_in(2)}
                    sx={{
                        margin: "0 0.5rem",
                        padding: "1rem",
                        fontSize: ".875rem",
                        backgroundColor: tabActive3_in === 2 ? "#002B38" : "white",
                        color: tabActive3_in === 2 ? "white" : "#002B38",
                        border: "1px solid #002B38",
                        cursor: "pointer",
                    }}
                >
                    ส่งแบบประเมินความพึงพอใจ
                </Box>
            </Box>
            {tabActive3_in === 1 && (
                <Box
                    p={1}
                    sx={{
                        backgroundColor: "white",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                        border: "2px solid white",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                >
                    <Box display="flex" alignItems="center" gap={1} mb={2}>
                        <SearchField
                            placeholder="ค้นหาชื่อไลน์"
                            value={term3}
                            onChange={(e) => setTerm3(e.target.value)}
                        />
                        <RedButton
                            sx={{ width: "40%", maxWidth: "117px", height: "40px", fontSize: ".75rem" }}
                            onClick={onSearchListEvaluation}
                        >
                            ค้นหา
                        </RedButton>
                    </Box>
                    <DataTable
                        pagination
                        paginationPerPage={50}
                        paginationRowsPerPageOptions={[50, 100, 500]}
                        columns={columns3}
                        data={isSearchEvaluation ? listEvaluationFiltered : listEvaluation}
                        customStyles={customStyles3}
                        progressPending={isLoading3}
                        progressComponent={
                            <Box
                                py={5}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    background: "#E7FBFF",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        }
                        noDataComponent={
                            <Box
                                py={5}
                                sx={{
                                    width: "100%",
                                    height: "100%",
                                    background: "#E7FBFF",
                                }}
                            >
                                <Typography align="center" sx={{ fontSize: "1.25rem", color: "#004B61" }}>
                                    ไม่พบรายชื่อไลน์
                                </Typography>
                            </Box>
                        }
                    />
                </Box>
            )}

            {tabActive3_in === 2 && (
                <Box
                    p={1}
                    sx={{
                        backgroundColor: "white",
                        borderBottomLeftRadius: "15px",
                        borderBottomRightRadius: "15px",
                        border: "2px solid white",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    }}
                >
                    <Box display="flex" alignItems="center" gap={1} mb={2}>
                        <SearchField
                            placeholder="ค้นหาชื่อไลน์"
                            value={term3}
                            onChange={(e) => setTerm3(e.target.value)}
                        />
                        <RedButton
                            sx={{ width: "40%", maxWidth: "117px", height: "40px", fontSize: ".75rem" }}
                            onClick={onSearchListLineUser}
                        >
                            ค้นหา
                        </RedButton>
                    </Box>

                    {isSearchLineUser &&
                        listLineUserFiltered.length > 0 &&
                        listLineUserFiltered.map((item) => (
                            <Box
                                key={item._id}
                                sx={{
                                    padding: ".5rem 0",
                                    borderBottom: "1px solid #EEE",
                                    "&:last-child": {
                                        borderBottom: "none",
                                    },
                                }}
                            >
                                <LineUserCard
                                    src={item.pictureUrl}
                                    name={item.displayName || item.lineuserid}
                                    onClick={() => sendEvaluation(item._id)}
                                />
                            </Box>
                        ))}

                    {isSearchLineUser && term3 && listLineUserFiltered.length <= 0 && (
                        <Box sx={{ fontSize: "1.25rem", textAlign: "center", padding: "1.5rem" }}>No data</Box>
                    )}

                    {!isSearchLineUser &&
                        listLineUser.map((item) => (
                            <Box
                                key={item._id}
                                sx={{
                                    padding: ".5rem 0",
                                    borderBottom: "1px solid #EEE",
                                    "&:last-child": {
                                        borderBottom: "none",
                                    },
                                }}
                            >
                                <LineUserCard
                                    src={item.pictureUrl || null}
                                    name={item.displayName || item.lineuserid}
                                    onClick={() => sendEvaluation(item._id)}
                                />
                            </Box>
                        ))}

                    <DialogSuccess
                        isOpen={sendEvaluateSuccess}
                        onClose={() => setSendEvaluateSuccess(false)}
                        text="ส่งแบบประเมินสำเร็จ"
                        btnText="ปิด"
                    />

                    <DialogLoading open={sendEvaluateLoading} message="กำลังส่งแบบประเมิน" />
                </Box>
            )}
        </Box>
    );
};

const SearchField = (props) => {
    const { value, onChange, placeholder } = props;

    return (
        <TextField
            fullWidth
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#004B61" }} />
                    </InputAdornment>
                ),
            }}
            sx={{
                fontSize: "0.875rem",
                borderRadius: "15px",
                "& .MuiInputBase-root": {
                    borderRadius: "15px",
                    backgroundColor: "white",
                },
                "& .MuiInputBase-input": {
                    padding: "0",
                    height: "40px",
                    color: "#004B61",
                },
                "& .MuiInputBase-input::placeholder": {
                    fontSize: "0.875rem",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "15px",
                    border: "2px solid #004B61",
                },
            }}
        />
    );
};

const LineUserCard = (props) => {
    const { src, name, onClick } = props;

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <ListItem alignItems="center" sx={{ paddingLeft: "0" }}>
                <ListItemAvatar>
                    <Avatar src={src} />
                </ListItemAvatar>
                <ListItemText primary={name} />
            </ListItem>
            <RedButton onClick={onClick} sx={{ width: "100%", maxWidth: "117px", fontSize: ".75rem" }}>
                ส่ง
            </RedButton>
        </Box>
    );
};

export default AdminTabThree;
