import { Helmet } from "react-helmet-async";
import { Box, Typography } from "@mui/material";
import bgHeader from "../../assets/images/bg/bg-header.png";
import bgStar from "../../assets/images/bg/bg-star.png";
import homeCar from "../../assets/images/pages/homepage/home-car.png"
import homeCard from "../../assets/images/pages/homepage/home-card.png";
import mainLogo from "../../assets/images/logo/main-logo.png";
import home2025 from "../../assets/images/pages/homepage/home-2025.png";
import giftBox from "../../assets/icon/giftbox.svg";

const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Homepage | Caltex StarCard</title>
            </Helmet>
            <Box
                component="main"
                maxWidth="md"
                sx={{
                    width: "100%",
                    paddingBottom: "3.57rem",
                    margin: "0 auto",
                    minHeight: "calc(100vh - 60px)",
                    background: `url(${bgStar}) no-repeat center center/ cover, #cceffd`,
                    overflow: "hidden",
                    position: "relative",
                    "&::after": {
                        content: '""',
                        width: "100%",
                        height: "100px",
                        background: `url(${homeCar}) no-repeat center center / cover`,
                        position: "absolute",
                        bottom: "245px",
                        left: "0"
                    }
                }}
            >
                <Box component="section" maxWidth="md" sx={{ padding: "0 0 5rem" }}>
                    <Box
                        sx={{
                            background: `url(${bgHeader}) no-repeat center center/ cover, #00566c`,
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            height: "60px",
                            marginBottom: "1rem",
                        }}
                    >
                        <Box
                            component="img"
                            src={mainLogo}
                            alt="Caltex Starclub Logo"
                            sx={{ height: "55px", display: "block" }}
                        />
                    </Box>
                    <Typography
                        component="h2"
                        sx={{
                            fontSize: "1.75rem",
                            fontWeight: "500",
                            color: "#004B61",
                            textAlign: "center",
                        }}
                    >
                        ยินดีต้อนรับสู่​
                    </Typography>
                    <Typography
                        component="h1"
                        sx={{
                            fontSize: "3rem",
                            fontWeight: "bold",
                            lineHeight: "2.5rem",
                            color: "#004B61",
                            textAlign: "center",
                        }}
                    >
                        สตาร์
                        <Box component="span" sx={{ fontWeight: "500" }}>
                            การ์ด คลับ
                        </Box>
                    </Typography>
                    <Box
                        component="img"
                        src={home2025}
                        alt="2024"
                        sx={{
                            width: "100%",
                            maxWidth: "150px",
                            display: "block",
                            margin: "10px auto -20px",
                        }}
                    />
                    <Box
                        component="img"
                        src={homeCard}
                        alt="Card"
                        sx={{
                            width: "100%",
                            maxWidth: "560px",
                            display: "block",
                            margin: "0 auto",
                        }}
                    />
                    <Box sx={{ position: "relative", zIndex: "2" }}>
                        <Typography
                            component="h3"
                            sx={(theme) => ({
                                fontSize: "1.9rem",
                                fontWeight: "bold",
                                color: "#004B61",
                                textAlign: "center",
                                lineHeight: "2rem",
                                marginTop: "-20px",
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: "2.5rem",
                                },
                            })}
                        >
                            เปลี่ยนทุกลิตรเป็นคะแนน​
                        </Typography>
                        <Typography
                            component="h3"
                            sx={(theme) => ({
                                fontSize: "1.75rem",
                                fontWeight: "400",
                                color: "#004B61",
                                textAlign: "center",
                                marginBottom: "1rem",
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: "2rem",
                                },
                            })}
                        >
                            แลกของรางวัลที่ถูกใจ
                        </Typography>
                        <Box
                            sx={{
                                width: "fit-content",
                                padding: "0 1rem",
                                backgroundColor: "#FF0000",
                                borderRadius: "30px",
                                margin: "40px auto 10px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                            }}
                        >
                            <Typography
                                component="h3"
                                sx={(theme) => ({
                                    fontSize: "1.5rem",
                                    fontWeight: "bold",
                                    color: "white",
                                    textAlign: "center",
                                    letterSpacing: "0.1rem",
                                    [theme.breakpoints.up("sm")]: {
                                        fontSize: "1.75rem",
                                    },
                                })}
                            >
                                1,000 ลิตร = 1 คะแนน
                            </Typography>
                            <Box component="img" src={giftBox} alt="Gift box" sx={{ width: "50px" }} />
                        </Box>
                        <Typography
                            component="p"
                            sx={(theme) => ({
                                fontSize: "1rem",
                                fontWeight: "500",
                                color: "#004B61",
                                textAlign: "center",
                                [theme.breakpoints.up("sm")]: {
                                    fontSize: "1.25rem",
                                },
                            })}
                        >
                            ตั้งแต่ 1 มกราคม 2568 - 30 พฤศจิกายน 2568 <br/>หมดเขตแลกของรางวัล 10 ธันวาคม 2568
                        </Typography>
                    </Box>
                    {/* <Box
                        component="img"
                        src={homeCar}
                        alt="Homepage Bg"
                        sx={{ width: "100%", position: "absolute", bottom: "200px", left: "0", zIndex: "0" }}
                    /> */}
                </Box>
            </Box>
        </>
    );
};

export default HomePage;
