import { Helmet } from "react-helmet-async";
import { Box } from "@mui/material";
import { useAppContext } from "../../hooks/useAppContext";
import RewardList from "./components/RewardList";
import bgStar from "../../assets/images/bg/bg-star.png";
import starIcon from "../../assets/icon/star-red.svg";
import reward from "../../assets/images/pages/reward/reward-main.png";

const RewardPage = () => {
    const { userInfo } = useAppContext();

    return (
        <>
            <Helmet>
                <title>Reward | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                maxWidth="md"
                mx="auto"
                sx={{
                    minHeight: "100vh",
                    background: `url(${bgStar}) no-repeat center center / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" sx={{ padding: "60px 0 120px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            position: "relative",
                        }}
                    >
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: ".5rem 1.5rem .5rem 2rem",
                            }}
                        >
                            <Box sx={{ fontSize: "1.75rem", fontWeight: "500", color: "#004B61" }}>Reward</Box>
                            <Box>
                                <Box sx={{ fontSize: "1rem", fontWeight: "500", color: "#FF0000", textAlign: "right" }}>
                                    คะแนนสะสม
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        gap: "5px",
                                        fontSize: "1.75rem",
                                        lineHeight: "1.75rem",
                                        fontWeight: "500",
                                        color: "#FF0000",
                                    }}
                                >
                                    <Box component="img" src={starIcon} alt="Star" sx={{ height: "22px" }} />
                                    {userInfo?.accumulated_points || "0"}
                                </Box>
                            </Box>
                        </Box>

                        <Box
                            component="img"
                            src={reward}
                            alt="reward"
                            sx={{ width: "100%", maxWidth: "450px", margin: "10px auto", display: "block" }}
                        />
                    </Box>
                    <RewardList />
                </Box>
            </Box>
        </>
    );
};

export default RewardPage;
