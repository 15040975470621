import { useEffect, useState } from "react";
import { useAppContext } from "../../hooks/useAppContext";
import { useLineContext } from "../../hooks/useLineContext";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../api/url";
import { Helmet } from "react-helmet-async";
import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import thirdBg from "../../assets/images/bg/third-bg.png";
import ShoppingCartItem from "./ShoppingCartItem";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import RedButton from "../../components/ui/RedButton";
import DialogInfo from "../../components/ui/DialogInfo";
import _ from "lodash";

const ShoppingCartPage = () => {
    const { liffToken, lineProfile } = useLineContext();
    const { companyInfo, setUserInfo, redeemLists, setRedeemLists } = useAppContext();
    const [dialogInfo, setDialogInfo] = useState(false);
    const [dialogInfoAddress, setDialogInfoAddress] = useState(false);
    const [dialogType, setDialogType] = useState("");
    const [listReward, setListReward] = useState([]);
    const [isOwner, setIsOwner] = useState(false);
    const [redeemBtnState, setRedeemBtnState] = useState(false);
    const navigate = useNavigate();
    const [step, setStep] = useState(1);

    const renderDialogInfo = (type) => {
        if (type === "SUCCESS") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => {
                        setDialogInfo(false);
                        navigate("/reward");
                    }}
                    text="แลกของรางวัลสำเร็จ"
                    btnText="ปิด"
                />
            );
        } else if (type === "NO_POINT") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="คะแนนของท่านไม่เพียงพอ"
                    btnText="ปิด"
                />
            );
        } else if (type === "NO_PERMISSION") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="บัญชีนี้ไม่มีสิทธิ์ในการแลกลองขวัญ"
                    btnText="ปิด"
                />
            );
        } else if (type === "NO_STOCK") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="ยังไม่พร้อมแลกของรางวัลชิ้นนี้"
                    btnText="ปิด"
                />
            );
        } else if (type === "NOT_ENOUGH_STOCK") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="รางวัลบางรายการถูกแลกครบจำนวนแล้ว"
                    btnText="ปิด"
                />
            );
        } else if (type === "OVER_LIMIT") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="ท่านได้แลกรางวัลบ้างรายการในตะกร้าถึง limit แล้ว"
                    btnText="ปิด"
                />
            );
        } else if (type === "OVER_QUOTA") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="รางวัลบ้างรายการในตะกร้าแลกได้สูงสุด 1 ชิ้น โปรดตรวจสอบรายละเอียดของรางวัล"
                    btnText="ปิด"
                />
            );
        } else if (type === "NOT_AVAILABLE") {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="คุณสามารถแลกรางวัลได้ใน วันที่ 17 มีนาคม 2568"
                    btnText="ปิด"
                />
            );
        } else {
            return (
                <DialogInfo
                    isOpen={dialogInfo}
                    onClose={() => setDialogInfo(false)}
                    text="ไม่พร้อมใช้งานลองใหม่ภายหลัง"
                    btnText="ปิด"
                />
            );
        }
    };

    const onRedeem = () => {
        if (companyInfo?.address?.length <= 0) {
            return;
        } else {
            setRedeemBtnState(true);
            axios
                .post(`${baseURL}/redeem_reward`, redeemLists, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                })
                .then((res) => {
                    if (res.data.Code === "20100") {
                        setDialogType("SUCCESS");
                        setRedeemLists([]);
                    } else if (res.data.Code === "40001") {
                        setDialogType("NO_POINT");
                    } else if (res.data.Code === "40307") {
                        setDialogType("NO_PERMISSION");
                    } else if (res.data.Code === "50001") {
                        setDialogType("NO_STOCK");
                    } else if (res.data.Code === "40002") {
                        setDialogType("NOT_ENOUGH_STOCK");
                        setRedeemLists([]);
                    } else if (res.data.Code === "40003") {
                        setDialogType("OVER_LIMIT");
                        setRedeemLists([]);
                    } else if (res.data.Code === "40004") {
                        setDialogType("OVER_QUOTA");
                        setRedeemLists([]);
                    } else if (res.data.Code === "40007") {
                        setDialogType("NOT_AVAILABLE");
                        setRedeemLists([]);
                    } else {
                        setDialogType("");
                    }
                    setRedeemBtnState(false);
                    setDialogInfo(true);

                    axios
                        .get(`${baseURL}/userinfo`, { headers: { Authorization: `Bearer ${liffToken}` } })
                        .then((res) => {
                            if (res.data.Code === "20000") {
                                setUserInfo(res.data.Data);
                            }
                        })
                        .catch((e) => {
                            throw new Error("Can't get user info", e);
                        });
                })
                .catch((e) => console.log(e));
        }
    };

    useEffect(() => {
        if (liffToken) {
            axios
                .get(`${baseURL}/listreward`, {
                    headers: { Authorization: `Bearer ${liffToken}` },
                })
                .then((res) => {
                    if (res.data.Code === "20000") {
                        setListReward(res.data.Data);
                    }
                })
                .catch((e) => {
                    throw new Error("Can't get list rewards", e);
                });
        }
    }, [liffToken, setListReward]);

    useEffect(() => {
        const filtered = _.find(companyInfo.user, { userId: lineProfile.userId });
        if (filtered) {
            setIsOwner(filtered.owner);
        }
    }, [companyInfo, lineProfile]);

    return (
        <>
            <Helmet>
                <title>Shopping cart | Caltex StarCard</title>
            </Helmet>

            <Box
                component="main"
                maxWidth="md"
                mx="auto"
                sx={{
                    height: "calc(100vh - 78px)",
                    overflow: "auto",
                    background: `url(${thirdBg}) no-repeat center -100px / cover`,
                    backgroundAttachment: "fixed",
                }}
            >
                <Box component="section" sx={{ padding: "60px 0 120px" }}>
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{
                            fontSize: "1rem",
                            color: "white",
                            margin: "10px 10px 1rem",
                            background: "linear-gradient(180deg, #365CA0, #264373, #152741)",
                            padding: "5px 10px",
                            borderRadius: "10px",
                        }}
                    >
                        <ArrowBackIosIcon
                            sx={{
                                fontSize: "0.875rem",
                                fontWeight: "400",
                                color: "white",
                            }}
                        />
                        ย้อนกลับ
                    </IconButton>

                    {step === 1 && (
                        <Box sx={{ padding: "0 10px" }}>
                            {redeemLists.length > 0 && (
                                <Box
                                    sx={{
                                        background: "linear-gradient(90deg, #FF0000, #A7002A)",
                                        color: "white",
                                        padding: "10px 20px",
                                        borderRadius: "15px",
                                        textAlign: "center",
                                        marginBottom: "20px",
                                    }}
                                >
                                    โปรดตรวจสอบรายการสินค้า
                                </Box>
                            )}
                            <Box>
                                {redeemLists.length > 0 &&
                                    redeemLists.map((item) => {
                                        const filtered = _.find(listReward, { _id: item.reward_id });

                                        return (
                                            <ShoppingCartItem
                                                key={item.reward_id}
                                                id={item.reward_id}
                                                quantity={item.quantity}
                                                name={filtered && filtered.name}
                                                image={filtered && filtered.image}
                                            />
                                        );
                                    })}

                                {redeemLists.length <= 0 && (
                                    <Box
                                        sx={{
                                            padding: "50px 10px",
                                            background: "#E7FBFF",
                                            borderRadius: "10px",
                                            textAlign: "center",
                                            border: "1px solid #C2DCFF",
                                            color: "#004B61",
                                        }}
                                    >
                                        ไม่มีรายการสินค้าในตะกร้า
                                    </Box>
                                )}

                                <Box>
                                    <Box
                                        sx={{
                                            fontSize: "14px",
                                            color: "#004B61",
                                            textAlign: "center",
                                            margin: "10px 0",
                                        }}
                                    >
                                        ของรางวัลอาจมีการเปลี่ยนแปลง สี หรือ รุ่น ในกรณีที่ของรางวัลหมด / เลิกผลิต
                                        เป็นรุ่นใกล้เคียงหรือเทียบเท่า
                                    </Box>
                                    <Box>
                                        <RedButton
                                            onClick={() => setStep(2)}
                                            disabled={
                                                !redeemBtnState && redeemLists && redeemLists.length > 0 ? false : true
                                            }
                                            sx={{ display: "block", margin: "0 auto", padding: "5px 50px" }}
                                        >
                                            ยืนยัน
                                        </RedButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {step === 2 && (
                        <Box sx={{ padding: "0 10px" }}>
                            <Box
                                sx={{
                                    background: "linear-gradient(90deg, #FF0000, #A7002A)",
                                    color: "white",
                                    padding: "10px 20px",
                                    borderRadius: "15px",
                                    textAlign: "center",
                                    marginBottom: "20px",
                                }}
                            >
                                โปรดตรวจสอบที่อยู่สำหรับจัดส่ง
                            </Box>

                            <Box
                                p={2}
                                mb={2}
                                sx={{ borderRadius: "10px", backgroundColor: "#E7FBFF", border: "1px solid #C2DCFF" }}
                            >
                                {companyInfo?.address?.length > 0 &&
                                    companyInfo.address.map((item) => {
                                        return (
                                            item.default && (
                                                <Grid
                                                    key={item._id}
                                                    container
                                                    rowGap={1}
                                                    columnGap={2}
                                                    sx={{ position: "relative", paddingRight: "2rem" }}
                                                >
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            ชื่อ - นามสกุล
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            เบอร์โทรศัพท์
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                            }}
                                                        >
                                                            {item.phonenumber}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            บ้านเลขที่ / หมู่บ้าน :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                                wordBreak: "break-word",
                                                            }}
                                                        >
                                                            {item.detail}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            ซอย / ถนน :
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                                wordBreak: "break-word",
                                                            }}
                                                        >
                                                            {item.alley} {item.street_name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            ตำบล
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                            }}
                                                        >
                                                            {item.subdistrict}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            อำเภอ
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                            }}
                                                        >
                                                            {item.district}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            จังหวัด
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                            }}
                                                        >
                                                            {item.province}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography
                                                            sx={{ fontSize: "1rem", fontWeight: "500", color: "#333" }}
                                                        >
                                                            รหัสไปรษณีย์
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={7}
                                                        alignSelf="center"
                                                        sx={{
                                                            padding: "5px 10px",
                                                            backgroundColor: "rgba(255, 0, 0, 0.2)",
                                                            borderRadius: "8px",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                fontSize: ".85rem",
                                                                fontWeight: "300",
                                                                color: "#6D6E71",
                                                            }}
                                                        >
                                                            {item.zip_code}
                                                        </Typography>
                                                    </Grid>
                                                    {isOwner && (
                                                        <IconButton
                                                            aria-label="แก้ไขที่อยู่"
                                                            onClick={() => navigate("/address")}
                                                            sx={{
                                                                padding: "0",
                                                                position: "absolute",
                                                                top: "0px",
                                                                right: "5px",
                                                            }}
                                                        >
                                                            <Edit sx={{ color: "#004B61" }} />
                                                        </IconButton>
                                                    )}
                                                </Grid>
                                            )
                                        );
                                    })}

                                {!companyInfo?.address && (
                                    <CircularProgress sx={{ display: "block", margin: "20px auto" }} />
                                )}

                                {companyInfo?.address?.length <= 0 && isOwner && (
                                    <>
                                        <Typography sx={{ fontSize: "1.25rem", fontWeight: "500", color: "#333" }}>
                                            เพิ่มที่อยู่
                                        </Typography>
                                        <IconButton
                                            aria-label="เพิ่มที่อยู่"
                                            onClick={() => navigate("/address/add")}
                                            sx={{
                                                display: "block",
                                                width: "100%",
                                                padding: ".5rem",
                                                marginTop: "20px",
                                                backgroundColor: "#E5EDEF",
                                                border: "2px solid white",
                                                borderRadius: "20px",
                                                "&:hover": {
                                                    backgroundColor: "#e5edefc1",
                                                },
                                            }}
                                        >
                                            <AddIcon sx={{ fontWeight: "bold", color: "#004B61" }} />
                                        </IconButton>
                                    </>
                                )}
                            </Box>
                            <Box>
                                <RedButton
                                    onClick={onRedeem}
                                    disabled={!redeemBtnState && redeemLists && redeemLists.length > 0 ? false : true}
                                    sx={{ display: "block", margin: "0 auto", padding: "5px 50px" }}
                                >
                                    ยืนยัน
                                </RedButton>
                            </Box>
                        </Box>
                    )}

                    <Box>{renderDialogInfo(dialogType)}</Box>
                </Box>
            </Box>

            <DialogInfo
                isOpen={dialogInfoAddress}
                onClose={() => setDialogInfoAddress(false)}
                text={`ไม่สามารถแลกของรางวัลได้\nโปรดเพิ่มที่อยู่ในการจัดส่ง`}
                btnText="ปิด"
            />
        </>
    );
};

export default ShoppingCartPage;
