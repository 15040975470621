import { Box, Typography } from "@mui/material";
import WhiteButton from "../components/ui/WhiteButton";
import bgStar from "../assets/images/bg/bg-star.png";

const NotFoundPage = () => {
    const redirectPage = () => {
        if (window.liff) {
            window.liff.login();
        }
    };

    return (
        <Box
            component="main"
            maxWidth="md"
            mx="auto"
            sx={{
                width: "100%",
                height: "calc(100vh - 78px)",
                padding: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "20px",
                background: `url(${bgStar}) no-repeat center center / cover`,
                backgroundAttachment: "fixed",
            }}
        >
            <Typography
                component="h1"
                sx={{
                    fontSize: "8rem",
                    fontWeight: "500",
                    lineHeight: "6rem",
                    color: "#ED1C24",
                    textAlign: "center",
                }}
            >
                404
            </Typography>
            <Typography
                component="h2"
                sx={{
                    fontSize: "1.75rem",
                    fontWeight: "500",
                    color: "#ED1C24",
                    marginBottom: ".75rem",
                    textAlign: "center",
                }}
            >
                Page not found
            </Typography>
            <Typography
                sx={{
                    fontSize: "1rem",
                    color: "white",
                    marginBottom: "1.25rem",
                    textAlign: "center",
                }}
            >
                It's looking like you may have taken a wrong turn. Don't worry...it happens to the most of us.
            </Typography>
            <WhiteButton
                sx={{ maxWidth: "250px", padding: ".5rem 2rem", margin: "0 auto", textTransform: "capitalize" }}
                onClick={redirectPage}
            >
                Go to homepage
            </WhiteButton>
        </Box>
    );
};

export default NotFoundPage;
